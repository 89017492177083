export default `
    <svg width="100%" height="544px" viewBox="0 0 612 585" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">

        <g id="departments-map">
            <a xlink:href="/departments/01" title="Ain">
                <path id="loc-01" class="land" d="M413.57,308.03L420.26,287.51L425.07,289.58L429.68,287.48L432.49,291.15L435.75,291.85L435.75,291.85L435.11,293.84L438.34,295.69L439.45,299.99L440.3,297.75L442.42,299.69L442.35,302.76L445.06,302.51L449.38,297.95L452.32,300.07L452.8,302.97L458.79,302.74L466.89,293.67L466.89,293.67L471.26,296.62L468.45,301.53L469.38,303.57L463.38,305.63L462.4,310.65L462.4,310.65L459.76,311.46L459.5,313.45L456.73,312.36L457.07,322.2L457.07,322.2L454.87,334.91L451.82,335.73L451.21,339.82L448.63,341.49L448.63,341.49L437.33,325.49L431.99,332.29L426.95,329.63L426.95,329.63L419.33,330.26L417.79,324.74L414.6,324.73L414.77,323.33L411.5,322.02L412.18,313.25L414.51,309.52z"/>
            </a>
            <a xlink:href="/departments/02" title="Aisne">
                <path id="loc-02" class="land" d="M346.84,70.19L354.77,68.56L360.05,69.76L362.25,67.4L367.05,69.02L369.34,66.45L375.88,68.52L376.51,70.35L380.45,68.11L380.38,71.37L387.12,72.25L387.12,72.25L390.82,73.57L390.82,73.57L391.7,79.39L389.74,85.06L391.56,86.53L386.43,91.59L385.96,94.47L382.16,95.18L384.42,98.43L383.18,108.95L383.18,108.95L382.7,111.88L378.01,108.92L375.18,110.61L375,112.86L371.95,112.13L366.44,114.85L366.8,121.24L370.72,124.66L365.48,125.19L364.59,127.14L366.14,129.82L363.94,132.36L366.66,131.97L367.78,133.8L359.78,144L359.78,144L358.78,144.92L355.54,142.76L354.9,139.21L353.3,140.49L352.3,137.93L350.63,138.55L346.6,133.92L346.49,128.46L342.66,127.33L342.66,127.33L345.78,124.58L343.86,122.21L343.34,124.65L343.16,122.54L339.98,121.06L340.6,123.93L337.97,120.39L341.36,120.01L341.28,116.56L339.11,116.45L338.4,113.98L341.53,113.88L343.58,107.21L346.53,106.78L344.13,105.11L343.58,102.12L345.41,98.19L343.54,95.15L344.66,89.81L344.66,89.81L344.66,89.81L344.66,89.81L344.66,89.81L344.66,89.81L341.95,81.73L348.12,71.96L346.09,71.49z"/>
            </a>
            <a xlink:href="/departments/03" title="Allier">
                <path id="loc-03" class="land" d="M309.79,293.44L313.12,287.63L320.42,287.48L323.66,285.42L321.81,279.05L324.21,279.07L324.06,277.16L327.86,273.84L329.74,275.55L333.28,275.02L336.37,270.96L340.96,270.84L340.96,270.84L348.05,278.03L350.83,275.54L354.06,277.53L357.12,275.72L359.68,279.56L362.94,277.23L362.39,275.19L364.44,275.1L364.42,272.61L365.78,273.6L365.78,273.6L370.48,286.3L374.51,286.74L375.66,289.29L379.25,289.08L381.18,290.92L381.38,298.98L377,302.07L377,302.07L371.55,304.27L374.17,318.6L369.08,320.13L368.46,322.71L368.46,322.71L364.21,317.15L358.94,318.08L358.84,314.98L357.06,314.11L353.45,315.85L345.04,314.36L343.47,311.69L340.27,312.42L336.09,307.92L337.44,304.15L336.34,303.56L332.7,303.79L331.77,306.83L328.57,305.26L324.68,311.46L321.71,310L321.71,310L318.18,301.81L310.7,298.03L312.05,296.46L310.08,295.74z"/>
            </a>
            <a xlink:href="/departments/04" title="Alpes-de-Haute-Provence">
                <path id="loc-04" class="land" d="M450.76,424.57L455.75,423.29L460.45,424.63L456.95,419.34L458.67,418.51L460.96,421.28L460.17,416.14L462.21,412.72L467.36,410.28L467.69,408L470.78,408.93L473.64,413.54L475.09,411.79L473.89,408.86L477.78,408.79L479.25,405.4L483.67,409.54L490.46,409.77L492.43,404.32L503.65,397.71L503.65,397.71L504.34,399.84L499.68,405.01L503.53,410.78L501.23,411.26L501.04,414.77L501.04,414.77L497.88,416.47L492.71,425.85L495.22,433.24L503.36,441.4L500.96,442.02L498.71,440.34L495.33,443L492.15,442.04L493.35,443.83L491.9,445.35L493.76,446.31L490.58,447.73L490.58,447.73L481.44,447.56L480.12,450.86L473.14,447.11L465.13,454.55L461.14,449.49L457.98,451.75L457.14,450.05L454.26,450.92L454.26,450.92L449.88,445.61L445.34,446.13L447.97,440.47L443.8,438.4L445.29,431.73L443.64,431.93L443.39,428.91L443.39,428.91L446.72,426.83L446.69,424.75L450.86,427.16L449.4,425.87z"/>
            </a>
            <a xlink:href="/departments/05" title="Hautes-Alpes">
                <path id="loc-05" class="land" d="M475.02,370.17L478.04,370.36L479.35,373.47L482.93,374.53L485.39,371.17L490.39,371.19L490.39,371.19L492.14,376.49L495.08,376.82L495.36,383.19L500.03,386.4L503.03,385.72L506.63,387.98L505.69,389.98L508.88,396.07L505.17,395.7L503.65,397.71L503.65,397.71L492.43,404.32L490.46,409.77L483.67,409.54L479.25,405.4L477.78,408.79L473.89,408.86L475.09,411.79L473.64,413.54L470.78,408.93L467.69,408L467.36,410.28L462.21,412.72L460.17,416.14L460.96,421.28L458.67,418.51L456.95,419.34L460.45,424.63L455.75,423.29L450.76,424.57L450.76,424.57L451.2,420.2L447.94,417.92L448.82,416.35L443.09,416.07L440.07,411.08L443.33,410.46L441.7,406.76L447.68,408.73L450.22,406.62L447.47,404.17L449.32,398.01L455.46,397.82L457.16,395.66L455.85,394.73L455.85,394.73L457.02,391.64L462.17,391.58L463.38,390.35L462.11,388.99L465.13,387.1L466.55,388.34L469.77,385.52L478.97,386.07L477.65,377.66L472.69,376.64z"/>
            </a>
            <a xlink:href="/departments/06" title="Alpes-Maritimes">
                <path id="loc-06" class="land" d="M501.75,414.95l5.18,7.73l6.51,1.3l7.06,4.96l13.57,-3.44l-0.72,2.52l2.01,2.79l-2.61,6.26l-6.42,5.76l1.45,4.98l-1.84,2.2l-6.14,2.08l-0.24,2.08l-0.74,-1.74l-6.58,3.05l-0.8,6.13l-1.64,-1.44l-2.48,2.04l-2.79,-0.59l-1.76,3.91l0,0l-2.1,-3l1.4,-3.96l-4.67,-1.67l-1.89,-6.46l-3.95,-0.41l-0.98,-2.33l0,0l3.18,-1.42l-1.86,-0.96l1.45,-1.51l-1.2,-1.79l3.18,0.95l3.38,-2.66l2.25,1.68l2.4,-0.61l-8.13,-8.16l-2.51,-7.39l5.17,-9.37l3.16,-1.71l0,0L501.75,414.95z"/>
            </a>
            <a xlink:href="/departments/07" title="Ardèche">
                <path id="loc-07" class="land" d="M412.6,356.09L414.48,360.17L414.48,360.17L415.71,368.36L414.53,370.29L417.98,381.21L412.8,390.81L413.6,397.37L409.99,403.2L408.19,416.54L408.19,416.54L408.14,419.98L408.14,419.98L402.16,415.74L400,416.05L399.37,419.24L397.61,418.83L397.46,415.59L394.74,416.06L391.87,420.3L386.58,416.16L383.35,417.28L384.05,412.18L381.13,409.04L381.13,409.04L379.72,403.52L377.17,401.23L375.44,392.52L375.44,392.52L378.02,391.1L379.09,387.7L382.37,387.31L382.63,385.05L387.69,385.01L390.53,379.74L394.22,379.16L393.5,376.45L397.07,375.17L395.56,373.6L397.75,371.43L396.71,369.15L400.18,370.61L401.23,363.73L401.23,363.73L406.17,362.87L406.75,359.27z"/>
            </a>
            <a xlink:href="/departments/08" title="Ardennes">
                <path id="loc-08" class="land" d="M415.86,60.99L418.38,62.12L415.21,66.75L416.09,68.49L413.95,72.95L418.05,76.76L416.34,79.51L416.53,84.24L422.5,83.79L426.61,86.18L429.6,90.63L433.93,90.47L436.47,93.15L435.74,95.88L439.14,95.54L439.14,95.54L433.6,100.31L427.38,97.09L425.16,102.93L427.32,105.65L425.9,109.17L426.9,110.72L423.85,113.44L424.8,117.33L420.67,119.68L420.67,119.68L419.53,117.84L417.59,118.05L417.14,120.28L409.84,118.38L407.51,120.02L406.01,116.23L399.73,117.42L396.96,114.14L393.97,114.04L391.45,110.42L383.18,108.95L383.18,108.95L384.42,98.43L382.16,95.18L385.96,94.47L386.43,91.59L391.56,86.53L389.74,85.06L391.7,79.39L390.82,73.57L390.82,73.57L394.05,72.9L399.71,74.94L409.7,71.02L410.32,64.79z"/>
            </a>
            <a xlink:href="/departments/09" title="Ariège">
                <path id="loc-09" class="land" d="M250.73,502.59L249.41,497.45L257.61,492.04L256.1,491.14L256.68,486.15L258.24,486.97L258.86,484.36L261.77,483.86L265.94,487.76L269.17,485.72L265.52,483.42L272.32,480.35L272.2,478.75L268.86,477.54L270.46,474.69L276.02,480.83L277.73,480.1L277.27,476.05L283.05,479.23L285.25,477.14L285.25,477.14L286.17,482.05L289.2,484.03L292,483.56L293.6,486.07L296.02,485.77L295.74,489.72L297.56,490.74L298.43,494.77L295.4,496.04L297.33,496.59L297.59,499.92L292.97,500.95L292.34,502.84L295.99,507.47L301.68,506.65L305.23,509.75L304.95,511.62L304.95,511.62L298.06,511.72L297.05,514.21L289.28,516.63L289.28,516.63L286.75,515.73L287.16,514.23L279.47,512.04L276.55,512.27L274.78,515.01L271.43,508.47L263.67,509.07L259.93,504.58L253.57,504.53z"/>
            </a>
            <a xlink:href="/departments/10" title="Aube">
                <path id="loc-10" class="land" d="M362.86,158.75L366.22,163.69L373.93,165.14L375.58,163.72L375.03,161.05L377.04,161.41L384.53,153.46L386.67,154.4L393.64,152.65L395.04,154.92L394.35,158.88L397.88,162.26L403.79,164.5L405.76,162.76L408.97,164.13L408.97,164.13L407.27,168.49L410.49,172.89L416.05,175.93L414.95,177.11L416.95,180.64L415.84,190.62L411.54,189.87L408.49,192.24L411.2,194.33L410.46,195.84L410.46,195.84L405.48,195.24L403.45,196.74L404.22,199.12L394.25,199.54L393.35,201.79L393.35,201.79L391.35,201.47L390.59,198.88L389.59,200.77L388.13,199.65L385.76,201.73L383.68,200.51L383.04,201.78L376.82,201.53L377.59,198.41L376.81,197.06L375.03,198.21L375.87,196.23L372.52,189.86L370.38,188.95L370.74,186.88L367.37,188.48L365.84,185.38L363.7,185.71L365.36,180.48L360.57,174.39L357.43,174.88L356.85,172.91L356.85,172.91L355.54,167.3L357.8,166.63L356.5,164.29L359.59,163.43L358.92,161.63z"/>
            </a>
            <a xlink:href="/departments/11" title="Aude">
                <path id="loc-11" class="land" d="M299.38,467.91L301.15,470.17L305.21,468.98L307.04,470.84L308.85,466.51L314.59,468.99L321.89,468.77L321.89,468.77L322.77,469.71L320.54,473L323.8,476.5L326.73,474.3L327.49,477.07L330.23,478L331.65,474.46L334.24,473.88L334.18,470.94L335.13,474.03L339.87,474.48L340.14,477.09L347.7,478.48L349.63,480.6L349.63,480.6L342.39,491.51L341.5,501.72L341.5,501.72L334.04,497.25L330.91,498.49L328.8,501.92L312.18,501.62L312.86,508.02L306.21,512.32L304.95,511.62L304.95,511.62L305.23,509.75L301.68,506.65L295.99,507.47L292.34,502.84L292.97,500.95L297.59,499.92L297.33,496.59L295.4,496.04L298.43,494.77L297.56,490.74L295.74,489.72L296.02,485.77L293.6,486.07L292,483.56L289.2,484.03L286.17,482.05L285.25,477.14L285.25,477.14L286.64,473.24L290.41,473.28L289.91,470.36L292.02,467.51L294.13,470.3L294.73,468.75L297.67,469.44z"/>
            </a>
            <a xlink:href="/departments/12" title="Aveyron">
                <path id="loc-12" class="land" d="M306.77,399.92L307.09,398.04L310.4,396.93L312.78,398.55L318.24,397.96L321.41,393.92L323.26,386.59L328.75,381.01L330.24,386L333.84,384.92L338.91,398.32L338.91,398.32L343.22,403.27L342.43,406.54L345.67,410.28L344.69,420.45L345.99,419.71L346.35,421.47L349.23,422.29L348.13,424.62L354.67,424.02L355.11,425.77L355.11,425.77L350.54,430.25L358.32,434.24L354.57,440.54L354.57,440.54L350.65,441.64L348.29,446.38L342.34,445.03L342.21,453.18L336.98,453.11L336.98,453.11L336.06,450.47L332.35,449.26L328.98,451.13L325.47,449.53L321.43,444.5L321.08,439.87L318.93,438.72L320.22,436.88L311.94,428.46L305.81,427.66L307.96,426.27L304.03,424.01L297.73,426.99L297.73,426.99L293.66,423.68L296.96,419.89L292.8,418.77L293.29,416.06L293.29,416.06L294.55,415.02L291.56,407.86L293.56,406.45L294.77,407.51L300.61,401.91L306.33,401.78z"/>
            </a>
            <a xlink:href="/departments/13" title="Bouches-du-Rhône">
                <path id="loc-13" class="land" d="M411.92,440.02l8.92,2.64l3.95,5.21l5.26,2.9l5.5,-0.14l9.08,4.49l3.15,0.03l6.26,-3.7l0,0l2.45,1.95l-0.71,1.7l-4.04,0.93l-1.8,4l2.86,1.31l-1.06,3.89l3.72,3.54l-4.41,1.24l0.93,2.46l-1.38,2.12l3.8,2.07l-3.33,2.66l-0.48,3.15l0,0l-2.03,-0.39l-0.74,1.51l-2.84,-3.09l-7.51,0.35l0.81,-2.65l-2.05,-5.72l-11.46,1.75l-3.32,-5.71l-3.38,0.67l-1.13,-2.35l0.69,2.52l-2.19,-0.8l2.22,3.91l-1.74,1.62l-10.56,-1.85l0.35,-3.51l-2.38,-1.71l-12.58,-0.46l0,0l0.44,-2.25l3.4,-1.65l-0.41,-1.55l0.83,1.11l5.32,-3.08l0.43,-1.32l-1.9,-0.66l2.47,-4.19l5.91,0.67l1.64,-9.12l-1.07,-1.25L411.92,440.02z"/>
            </a>
            <a xlink:href="/departments/14" title="Calvados">
                <path id="loc-14" class="land" d="M227.47,107.26L228,116.76L231.08,116.63L228.46,119L231.53,121.39L231.14,124.97L233.14,125.78L232.58,129.92L230.41,131.35L233.7,133.45L232.34,137.85L232.34,137.85L226.64,136.79L221.54,139.47L220.34,137.77L212.29,144.77L208.96,145.04L209.89,146.27L202.44,143.66L200.73,145.88L198.15,142.83L198.15,142.83L191.34,145.4L191.34,145.4L186.34,144.34L185.47,147.27L180.23,150.29L180.23,150.29L180.98,149.06L178.46,150.04L175.62,147.97L170.17,148.68L167,145.58L172.79,140.59L172.67,139.28L170.41,139.56L171.22,137.33L175.86,136.86L179.38,132.79L178.6,127.29L176.77,128.19L178.26,126.47L175.28,124.4L178.26,121.94L176.66,120L174.67,122.59L170.34,119.86L167.94,117.55L168.74,112.91L168.74,112.91L170.48,110.01L175.62,109.57L180.99,112.1L198.74,113.62L205.91,116.84L215.06,114.14L220.39,109.28L226.95,107.28L226.95,107.28z"/>
            </a>
            <a xlink:href="/departments/15" title="Cantal">
                <path id="loc-15" class="land" d="M319.27,349.45L326.73,351.86L327.82,355.16L334.86,355.06L339.67,360.56L344.32,356.93L344.32,356.93L343.08,360.62L348.91,361.34L351.66,370.51L354.63,371.5L351.41,371.52L354.66,379.27L354.66,379.27L351.52,381.85L349.7,381.34L347.63,385.61L345.57,383.27L343.96,384.23L338.91,398.32L338.91,398.32L333.84,384.92L330.24,386L328.75,381.01L323.26,386.59L321.41,393.92L318.24,397.96L312.78,398.55L310.4,396.93L307.09,398.04L306.77,399.92L306.77,399.92L303.53,395.28L305.27,389.93L301.93,384.98L300.7,379.01L300.7,379.01L304.08,377.52L302.24,374.09L305.33,372.79L305.6,369.57L307.83,367.73L306.2,364.6L313.08,357.82L312.86,353.21L318.1,355.86L319.9,355.16L318.39,352.96z"/>
            </a>
            <a xlink:href="/departments/16" title="Charente">
                <path id="loc-16" class="land" d="M223.12,312.87L232.82,315.68L235.07,314.65L233.52,312.5L235.53,310.41L239.84,314.26L243.64,312.84L244.71,310.23L249.34,310.91L249.34,310.91L248.91,315.51L253.57,318L254.18,321.11L250.81,323.49L248.82,322.6L247.6,330.95L244.66,330.2L244.79,332.69L241.28,335.78L241.28,335.78L238.59,340.31L236.33,340.34L236.06,345.14L233.04,349.01L228.84,351.6L227.67,350.58L225.39,355.89L226.18,360.14L220.73,365.5L217.4,364.27L215.31,366.35L215.31,366.35L215.26,364.2L210.32,363.01L210.53,360.55L205.98,358.67L203.31,359.45L204.95,356.38L202.22,354.97L205.37,353.45L203.22,350.77L205.08,348.05L201.74,346.13L202.27,343.64L197.22,340.82L199.24,339.04L197.58,337.23L198.48,334.27L195.98,333.77L203.19,330.02L208.68,331.65L210.47,326.2L208.89,322.94L211.07,322.82L210.85,320.37L210.85,320.37L213.54,319L213.6,315.34L215.89,315.45L219.2,312.27z"/>
            </a>
            <a xlink:href="/departments/17" title="Charente-Maritime">
                <path id="loc-17" class="land" d="M156.7,315.7l7.04,3.83l2.08,5.78l-1.79,5.19l-1.58,-4.83l-4.89,-4.34L156.7,315.7zM152.41,303.18l1.52,1.4l-1.65,-0.02l0.51,1.81l3.66,-1.56l-1.1,1.29l7.83,2.76l-2.4,1.19l-9.32,-3.54l-1.17,-2.49L152.41,303.18zM168.78,299.64l7.73,-3.27l-1.31,2.94l1.17,0.68l5.48,-1.91l2.15,2.28l0,0l-0.14,3.52l2.64,1.66l-0.1,2.24l3.59,1.35l-0.59,1.27l2.77,-0.54l1.96,2.41l3.53,-0.47l2.35,2.31l3.05,-0.71l1.32,2.1l4.13,0.86l2.33,4.02l0,0l0.22,2.45l-2.18,0.13l1.58,3.26l-1.79,5.45l-5.49,-1.63l-7.2,3.74l2.5,0.5l-0.9,2.96l1.66,1.81l-2.01,1.78l5.04,2.83l-0.53,2.49l3.33,1.92l-1.86,2.72l2.15,2.68l-3.15,1.53l2.73,1.41l-1.64,3.07l2.68,-0.78l4.54,1.88l-0.21,2.46l4.94,1.19l0.05,2.16l0,0l-1.82,5.27l0,0l-2.31,-1.15l-3.97,1.58l-6.63,-4.4l-1.37,1.53l-1.64,-7.83l-5.93,-1.15l-0.93,-2.95l-0.34,1.52l-4.97,-0.42l0,0l-4.33,-9.68l-5.88,-3.82l-2.58,-3.88l-8.82,-4.13l-0.06,-5.54l4.3,-1l-1.69,-3.2l3.01,-0.81l1.14,-3.32l-1.35,-4.08l2.27,-0.46l-4.42,-9.12l-2.96,0.09l1.2,-3.45l3.9,-3.25L168.78,299.64z"/>
            </a>
            <a xlink:href="/departments/18" title="Cher">
                <path id="loc-18" class="land" d="M308.06,220.56L310.17,220.02L313.59,222.69L316.32,221.21L318.54,223.75L321.52,223.52L325.88,228.89L328.22,228.27L328.52,225.57L331.65,228.23L334.45,226.65L334.45,226.65L336.79,231.72L334.17,237.81L338.9,242.49L340.4,254.51L342.89,256.97L340.96,270.84L340.96,270.84L336.37,270.96L333.28,275.02L329.74,275.55L327.86,273.84L324.06,277.16L324.21,279.07L321.81,279.05L323.66,285.42L320.42,287.48L313.12,287.63L309.79,293.44L309.79,293.44L305.13,293.16L305.13,293.16L304.47,291.13L306.65,289.59L304.72,285.24L306,280L301.76,275.8L300.95,273.49L303.01,271.73L299.94,268.13L301.31,268.45L304.59,263.79L301.12,262.46L302.62,258.63L299.33,255.52L300.18,251.9L298.32,252.69L297.94,250.73L293.03,252.21L288.84,250.55L291.65,247.56L291.55,245.04L291.55,245.04L294.07,245.45L293.8,242.9L295.74,240.85L299.18,242.53L304.72,240.28L302.17,236.14L302.93,232.77L305.99,231.73L307.72,233.6L308.43,228.44L306.34,228.73L306.4,225.16L303.49,224.82L303.13,222.87z"/>
            </a>
            <a xlink:href="/departments/19" title="Corrèze">
                <path id="loc-19" class="land" d="M293.91,336.46L300.25,332.48L301.68,334.61L304.93,334.15L306.2,336.4L309.33,336.84L309.26,338.46L318.57,334.08L318.57,334.08L320.16,338.8L317.31,342.51L319.65,344.98L319.27,349.45L319.27,349.45L318.39,352.96L319.9,355.16L318.1,355.86L312.86,353.21L313.08,357.82L306.2,364.6L307.83,367.73L305.6,369.57L305.33,372.79L302.24,374.09L304.08,377.52L300.7,379.01L300.7,379.01L296,380.39L294.35,378.91L289.95,382.12L283.49,375.93L278.99,374.84L275.25,376.42L275.25,376.42L272.34,371.41L273.75,370.25L269.49,369.79L267.17,367.97L268.66,366.51L266.11,365.59L268.21,363.42L266.09,361.59L266.46,358.72L270.15,355.2L267.4,354.21L268.83,352.13L267.16,351.5L267.16,351.5L268.51,348.76L271.17,350.12L276.48,344.34L280.84,344.88L289.28,337.48L292.92,338.44z"/>
            </a>
            <a xlink:href="/departments/2A" title="Corse-du-Sud">
                <path id="loc-2A" class="land" d="M571.02,527.46l8.21,3.13l3.78,-0.3l1.85,4.27l5.8,2.59l4.81,10.18l2.52,-0.15l0.43,9.91l3.51,0.96l3.54,-1.5l0,0l-0.56,11.21l-1.42,2.22l-1.93,-0.65l-0.89,2.92l1.32,-1.61l2.12,0.59l-3.96,3.53l0.2,3.66l-2.58,3.03l2.05,-0.85l-2.13,3.13l-4.98,-1.37l0.86,-2.59l-2,-1.41l-9.33,-2.88l0.1,-1.59l-2.41,-0.52l-0.07,-3.46l5.41,-3.83l-5.58,-0.67l-0.28,-2.29l-4.82,0.04l2.98,-2l-0.77,-1.43l2.48,-0.52l1.3,-4.66l-2.28,-2.11l-5.83,2.2l-0.63,-3.97l3,-0.7l-0.19,-1.94l3.62,-2.07l-1.97,-3.49l-1.69,0.66l-4.23,-2.51l1.49,-1.26l-2.22,-3.87l6.35,-1.73l-3.68,-2.08l0.15,-2.48l-2.3,0.9L571.02,527.46z"/>
            </a>
            <a xlink:href="/departments/2B" title="Haute-Corse">
                <path id="loc-2B" class="land" d="M606.32,492.01l1.68,1.41l1.22,10.22l-1.96,7.41l3.6,7.06l1.08,19.75l-0.37,5.08l-5.72,8.4l-0.39,5.22l0,0l-3.54,1.5l-3.51,-0.96l-0.43,-9.91l-2.52,0.15l-4.81,-10.18l-5.8,-2.59l-1.85,-4.27l-3.78,0.3l-8.21,-3.13l0,0l3.46,-2.02l0.42,-5.54l2.23,-0.54l-0.45,-2.89l3.22,1.04l0.63,-2.49l8.85,-2.18l4.55,-5.08l4.03,-0.21l3.19,3.3l1.91,-3.34l-1.47,-5.47l2.03,-5.22l-0.76,-3.87L606.32,492.01z"/>
            </a>
            <a xlink:href="/departments/21" title="Côte-d'Or">
                <path id="loc-21" class="land" d="M386.08,250.86l-0.07,-1.31l3.01,-0.29l0,0l-0.45,2.27L386.08,250.86zM393.35,201.79l0.9,-2.25l9.98,-0.41l-0.78,-2.39l2.03,-1.49l4.98,0.6l0,0l3.67,0.93l-0.35,2.69l3.35,1.36l-1.4,1.95l2.97,-0.76l3.76,6.27l-3.23,3.24l1.79,0.31l0.41,4.68l2.5,-1.5l3.69,3.94l2.53,-2.08l-0.2,1.73l3.55,1.84l-0.32,2.79l2.27,-2.01l2.72,0.26l0,0l2.3,-1.61l2.11,1.54l0.37,4.85l-5.01,3.85l2.98,0.86l-0.27,3.54l2.77,0.2l-1.26,4.44l2.07,0.79l0,0l-3.31,9.81l-2.23,3.68l-4.2,2.01l1.43,2.2l-2.58,2l0,0l-6.57,2.15l-2,-2.24l-6.42,0.89l-8.59,4.05l-5.83,-5.59l0.29,-1.72l-5.27,-1.06l-1.03,-2.73l-1.99,1.17l-0.34,-2.31l-3.01,-0.4l-2.65,-2.92l0,0l0.75,-3.25l-2.05,-2.79l-1.86,0.98l0.39,-7.11l-1.52,0.93l0,0l-1.71,-4.16l2.38,-2.5l-0.33,-4.09l5.6,-8.89l-0.92,-1.15l2.34,-0.21l-1.88,-2.58l3.93,-2.01l-0.12,-5.55l-2.48,0.5l-1.01,-2.15l2.72,-1.92L393.35,201.79z"/>
            </a>
            <a xlink:href="/departments/22" title="Côtes-d'Armor">
                <path id="loc-22" class="land" d="M81.25,143.01l0.35,2.02l5.31,-1.91l0.12,2.91l3.06,-0.19l-1.42,2.39l4.69,1.71l-0.51,2.32l5.32,4.52l-0.2,3.35l4.49,2.47l1.17,3.59l0.14,-2.14l2.11,0.39l3.21,-4.6l3.36,-1.43l-0.62,-1.41l2.92,0.79l4.14,-3.52l1.32,1.58l-2.09,2.71l3.88,-1.52l1.05,4.93l0.9,-2.83l0.7,2.17l1.19,-2.62l1.04,1.14l0,0l3.35,3.61l1.54,-1.56l0,0l1,4.24l1.35,-2.44l0,0l1.42,0.14l0.29,3.37l-1.74,2.43l0.98,5.94l-1.66,0.29l0.44,3.16l-4,-0.34l-2.18,2.87l-3.2,0.12l-2.15,6.54l-2.13,0.6l0,0l-2.91,1.44l-2.77,-3.88l-4.61,1.01l-0.54,5.04l-4.49,2.74l-0.17,-6.11l-3.91,0.88l-0.8,1.83l-1.27,-3.59l-8.12,-0.96l-1.56,-2.71l-3.23,-0.19L84.77,187l-5.48,1.5l-2.66,-1.93l-3.38,1.55l-1.15,-2.61l-4.98,0.08l0,0l1.91,-0.26l-1.59,-2.1l1.63,-5.77l-1.39,0.09l-0.15,-3.91L65.46,173l2.26,-3.62l-2.31,-1.95l2.81,-3.73l-4.25,-3.58l-0.74,-3.97l0,0l3.77,-1.5l-0.47,-7.78l1.03,0.92l2.09,-3l4.41,2.46L81.25,143.01z"/>
            </a>
            <a xlink:href="/departments/23" title="Creuse">
                <path id="loc-23" class="land" d="M273.88,297.86L278.47,293.02L279.26,295.07L281.94,293.34L282.73,295.34L285.1,293.52L286.9,295.21L289.14,291.23L291.47,293.08L305.13,293.16L305.13,293.16L309.79,293.44L309.79,293.44L310.08,295.74L312.05,296.46L310.7,298.03L318.18,301.81L321.71,310L321.71,310L321,313.4L323.45,320.49L318.61,326.63L314.28,328.81L318.57,334.08L318.57,334.08L309.26,338.46L309.33,336.84L306.2,336.4L304.93,334.15L301.68,334.61L300.25,332.48L293.91,336.46L293.91,336.46L293.26,330.68L288.72,328.51L288.66,326.47L284.07,328.37L281.21,325.61L283.36,325L283.19,322.96L277.81,322.57L280.56,319.82L278.73,317.98L279.05,313.81L276.75,312.28L277.24,310.07L272.15,305.7L274.8,302.32z"/>
            </a>
            <a xlink:href="/departments/24" title="Dordogne">
                <path id="loc-24" class="land" d="M241.28,335.78L247.29,338.31L246.32,341.25L248.84,343.78L251.19,340.95L252.37,342.23L257.39,341.66L260.19,346.37L263.55,346.68L261.66,349.63L267.16,351.5L267.16,351.5L268.83,352.13L267.4,354.21L270.15,355.2L266.46,358.72L266.09,361.59L268.21,363.42L266.11,365.59L268.66,366.51L267.17,367.97L269.49,369.79L273.75,370.25L272.34,371.41L275.25,376.42L275.25,376.42L273.67,377.23L274.92,384.83L269.1,389.57L269.82,392.82L262.87,396.9L259.9,402.48L259.9,402.48L255.91,398.36L249.81,400.91L250.59,396.92L248.28,395.05L243.63,396.5L241.22,394.72L238.11,397.06L229.99,397.69L227.55,391.4L227.55,391.4L226.11,387.73L228.14,386.58L226.62,385.33L225.01,385.04L223.03,388.12L213.72,386.23L216.54,382.61L215.21,380.67L218.16,373.47L216.99,370.82L213.49,371.62L213.49,371.62L215.31,366.35L215.31,366.35L217.4,364.27L220.73,365.5L226.18,360.14L225.39,355.89L227.67,350.58L228.84,351.6L233.04,349.01L236.06,345.14L236.33,340.34L238.59,340.31z"/>
            </a>
            <a xlink:href="/departments/25" title="Doubs">
                <path id="loc-25" class="land" d="M451.79,242.27L460.01,239.49L461.25,237.37L463.37,238.6L468.92,236.44L469.1,234.26L471.25,234.69L472.77,232.13L474.22,233.24L478.13,227.52L481.31,226.53L483.79,228.73L484.97,227.24L487.32,228.51L488.33,225.17L491.14,225.69L491.61,222.99L496.09,225.61L497.69,224.1L497.69,224.1L501.62,224.88L503.4,227.11L501.85,229.58L503.24,232.05L503.24,232.05L500.7,236.95L506.4,235.72L508.03,238.11L503.36,240.96L503.87,243.68L493.39,253.46L494.07,255.35L482.13,262.8L483.47,265.17L482.98,272.06L469.45,283.25L469.94,285.16L469.94,285.16L466.24,282.16L468.58,279.49L467.12,277.23L472.77,272.7L470.48,269.22L465.54,267.53L460.99,257.44L457.86,258.29L456.29,255.6L453.55,257.13L453.23,255.07L455.26,255.05L454.63,252.67L457.04,249.87z"/>
            </a>
            <a xlink:href="/departments/26" title="Drôme">
                <path id="loc-26" class="land" d="M414.48,360.17l7.77,-2.75l3.6,3.64l2.57,-0.9l-0.71,2.92l3.37,1.75l-1.47,0.97l0.81,4.75l-2.08,2.73l3.11,-0.59l7.24,2.88l4.06,-2.89l-1.05,16.62l4.01,1.87l-0.25,-1.6l4.08,4.11l6.3,1.06l0,0l1.31,0.93l-1.7,2.15l-6.14,0.2l-1.85,6.15l2.76,2.46l-2.55,2.11l-5.98,-1.97l1.63,3.71l-3.25,0.61l3.02,4.99l5.73,0.28l-0.88,1.58l3.26,2.27l-0.44,4.38l0,0l-1.36,1.3l1.47,1.29l-4.18,-2.41l0.03,2.08l-3.33,2.09l0,0l-4.79,-2.27l-1.16,-3.41l-8.29,-0.92l0.51,-4.9l-2.46,2.16l-2.02,-1.74l-10,4.63l-1.26,-4.96l-5.73,-0.97l0,0l1.8,-13.33l3.61,-5.83l-0.8,-6.56l5.18,-9.6l-3.45,-10.92l1.17,-1.92L414.48,360.17zM422.21,411.13L422.21,411.13l-3.25,1.52l-0.85,5.35l4.05,0.68l3.49,-4.85l-2.86,-2.06L422.21,411.13z"/>
            </a>
            <a xlink:href="/departments/27" title="Eure">
                <path id="loc-27" class="land" d="M227.47,107.26L235.88,103.93L239.45,107.35L241.77,106.54L242.6,109.21L248.4,107.59L251.61,111.11L253.22,110.29L253.29,113.24L250.83,112.71L250.19,114.16L252.59,115.83L254.14,114.27L254.8,117.53L256.92,118.81L259.26,118.33L258.65,115.79L262.28,115.09L263.26,113.05L268.02,112.77L269.53,107.5L272.72,105.34L280.54,106.86L283.86,109.41L286.29,108.89L286.29,108.89L290.02,117.35L289.4,119.16L288.11,117.49L286.09,117.98L285.85,120.07L285.85,120.07L281.9,129.82L281.9,129.82L277.81,129.3L276.93,131.5L275.26,131.32L276.18,136.22L278.2,136.05L277.46,138.46L277.46,138.46L277.46,138.46L277.46,138.46L275.25,139.51L275.76,142.76L271.68,145.12L272.11,148.47L270.32,149.81L261.7,147.95L261.42,150.66L259.28,149.9L257.26,151.96L251.52,152.59L248.99,155.44L248.99,155.44L245.33,153.13L247.24,151.2L246.58,149.33L240.46,145.26L240.71,142.39L232.69,141.83L231.09,140.22L232.34,137.85L232.34,137.85L233.7,133.45L230.41,131.35L232.58,129.92L233.14,125.78L231.14,124.97L231.53,121.39L228.46,119L231.08,116.63L228,116.76z"/>
            </a>
            <a xlink:href="/departments/28" title="Eure-et-Loir">
                <path id="loc-28" class="land" d="M277.46,138.46L281.49,144.82L281.73,155.97L289.31,162.76L289.91,168.19L292.74,169.83L294.91,168.73L294.91,168.73L295.15,171.97L297.21,172.25L296.39,177.92L297.89,179.38L297.89,179.38L296.98,186.41L294.14,187.24L293.28,191.09L287.55,193.22L282.57,192.82L281.33,195.27L277.9,195.49L278.28,198.27L278.28,198.27L275.33,196.74L272.01,199.99L264.81,199.15L261.34,192.1L256.36,191.54L258.34,188.96L254.56,191.06L250.04,190.74L250.04,190.74L250.45,188.91L253.14,188.57L248.24,185.13L248.24,185.13L249.59,183.93L247.09,177.16L253.07,174.02L255.64,169.8L253.98,167.54L255.25,164.6L249.13,159.07L248.99,155.44L248.99,155.44L251.52,152.59L257.26,151.96L259.28,149.9L261.42,150.66L261.7,147.95L270.32,149.81L272.11,148.47L271.68,145.12L275.76,142.76L275.25,139.51z"/>
            </a>
            <a xlink:href="/departments/29" title="Finistère">
                <path id="loc-29" class="land" d="M2.78,168.42l1.57,1.49l-4.33,0.67L2.78,168.42zM49.78,152l1.68,3.76l0.69,-2.14l3.29,2.4l0.97,-3.69l7.43,1.47l-0.61,2.33l0,0l0.74,3.97l4.25,3.58l-2.81,3.73l2.31,1.95L65.46,173l2.06,0.63l0.15,3.91l1.39,-0.09l-1.63,5.77l1.59,2.1l-1.91,0.26l0,0l-6.21,2.77l-0.83,1.91l3.96,7.85l4.48,-0.25l2.65,2.67l2.1,-1.75l1.04,0.99l-0.29,5.87l-2.06,-0.04l-1.74,2.46l-1.56,-1.35l-0.25,5.07l0,0l-8.02,-2.58l-5.28,0.88l-4.18,-6.41l-0.79,2.48l-7.13,-0.14l-1.86,3.72l-7.31,0.01l0.81,-3.42l-3.03,-6.84l-3.94,-2.7l-9.16,-2.04l0.93,-1.58l12.37,-2.63l5.28,0.38l0.67,-3.32l-1.76,-3.08l-7.18,-1.99l-2.91,4.08l0.64,-4.89l-3.53,-1.9l2.43,-0.16l-0.07,-3.02l1.63,-0.64l-0.13,3.55l1.49,-1.7l-0.13,1.9l3.48,-0.83l0.85,1.21l3.78,-1.44l1.93,1.1l1.62,-2.09l-4.79,-0.08l1.45,-1.07l-1.1,-1.43l-5.38,1.77l2.41,-3.53l-0.98,-0.78l-7.63,3.58l-3.06,-1.06l-3.82,1.62l-0.93,-5.23l1.8,-8.12l5.86,-2.06l2.84,-3.79l2.69,0.83l5.49,-3.36l2.04,0.79l0.01,1.94l4.04,-1.18l0.51,-2.16l5.28,0.66l0.25,-1.86L49.78,152z"/>
            </a>
            <a xlink:href="/departments/30" title="Gard">
                <path id="loc-30" class="land" d="M381.13,409.04l2.93,3.15l-0.71,5.1l3.23,-1.12l5.29,4.14l2.87,-4.24l2.73,-0.47l0.15,3.24l1.76,0.42l0.63,-3.19l2.16,-0.31l5.98,4.23l0,0l2.99,4.8l-0.04,6.1l5.12,4.28l-4.28,4.86l0,0l-4.11,3.22l1.07,1.25l-1.64,9.12l-5.91,-0.67l-2.47,4.19l1.9,0.66l-0.43,1.32l-5.32,3.08l-0.83,-1.11l0.41,1.55l-3.4,1.65l-0.44,2.25l0,0l-3.79,-1.3l-1.65,-4.09l0,0l-0.77,-2.05l2.92,0.27l1.78,-3.22l-2.09,-5.43l-5.67,-4.55l-1.76,0.73l0.62,-2.26l-2.62,-2.61l-4.67,0.52l0.98,-3.28l-1.67,-1.75l-4.27,0.8l-4.37,6.28l-2.1,-0.64l-0.64,-2.41l-3.36,1.93l-0.32,-2.8l-2.73,-0.13l0,0l3.75,-6.3l-7.78,-3.98l4.58,-4.48l0,0l7.08,3.24l3.73,-0.42l1.62,-3.77l5.21,3.41l3.25,0.07l2.01,-3.26l2.1,1.15l-1.54,-1.88l1.58,-3.61l-2.15,-2.73l1.21,-1.54l-2.76,-3.22l2.64,-0.72L381.13,409.04z"/>
            </a>
            <a xlink:href="/departments/31" title="Haute-Garonne">
                <path id="loc-31" class="land" d="M254.71,447.79L262.87,445.8L265.51,448.94L271.52,446.1L268.88,444.47L271.53,444.13L271.79,441.95L275.29,443.04L276.44,440.86L277.05,442.09L279.67,440.24L279.67,440.24L281.08,446.11L283.38,447.06L283.63,449.94L285.96,451.86L284.2,453.17L286.59,453.46L285.27,457.02L291.49,459.79L294.64,464.16L297.74,465.5L299.81,463.55L299.38,467.91L299.38,467.91L297.67,469.44L294.73,468.75L294.13,470.3L292.02,467.51L289.91,470.36L290.41,473.28L286.64,473.24L285.25,477.14L285.25,477.14L283.05,479.23L277.27,476.05L277.73,480.1L276.02,480.83L270.46,474.69L268.86,477.54L272.2,478.75L272.32,480.35L265.52,483.42L269.17,485.72L265.94,487.76L261.77,483.86L258.86,484.36L258.24,486.97L256.68,486.15L256.1,491.14L257.61,492.04L249.41,497.45L250.73,502.59L250.73,502.59L243.24,500.81L241.87,504.86L243.14,510.04L234.84,509.55L234.84,509.55L233.72,507.66L234.91,499.52L238.44,500.48L241.94,494.84L240.35,490.69L237.05,492.36L238.61,488.38L233.4,485.22L236.59,480.56L238.91,480.13L237.99,479.22L241.49,475.85L240.35,475.03L240.35,475.03L246.91,468.96L253.18,469.54L256.12,471.91L258.49,466.59L257.33,465.79L259.65,463.96L258.73,461.92L264.94,460.26z"/>
            </a>
            <a xlink:href="/departments/32" title="Gers">
                <path id="loc-32" class="land" d="M218.27,436.56L220.53,435.49L221.63,437.14L224.03,434.27L227.61,436.11L239.76,430.93L242.7,434.15L245.95,431.77L245.95,431.77L246.91,433.82L250.61,432.81L246.64,439.27L252.64,441.44L252.27,447.57L254.71,447.79L254.71,447.79L264.94,460.26L258.73,461.92L259.65,463.96L257.33,465.79L258.49,466.59L256.12,471.91L253.18,469.54L246.91,468.96L240.35,475.03L240.35,475.03L228.89,473.16L227.44,470.6L222.69,471.64L219.99,463.38L217.32,463.2L214.54,458.21L211.13,459.54L211.13,459.54L205.06,459.38L205.06,459.38L203.43,456.08L207.06,450.66L205.74,446.6L207.11,446.5L207.42,443.35L205.2,441.48L207.69,439.08L211.16,439.7L213.63,436.5L215.42,438.24L214.26,439.67L217.58,441.56z"/>
            </a>
            <a xlink:href="/departments/33" title="Gironde">
                <path id="loc-33" class="land" d="M171.04,344.59l-0.22,2.91l10.89,9.76l4.79,15.18l3.9,4.58l-3.05,-7.07l-1.94,-11.61l0,0l4.97,0.42l0.34,-1.52l0.93,2.95l5.93,1.15l1.64,7.83l1.37,-1.53l6.63,4.4l3.97,-1.58l2.31,1.15l0,0l3.5,-0.8l1.17,2.64l-2.95,7.2l1.33,1.94l-2.82,3.62l9.31,1.89l1.99,-3.08l1.6,0.29l1.52,1.24l-2.03,1.16l1.44,3.67l0,0l-2.92,-0.14l-0.37,2.39l-1.27,-1.62l-3.62,2.95l3.51,2.32l-4.77,6.48l-2.81,-0.07l-0.67,7.67l1.62,2.46l-4.26,1.23l1.87,4.78l-4.48,2.61l0,0l-2.12,-2.66l-1.46,0.49l0.04,3.48l-6.93,-0.16l0.23,-4.61l-10.68,-6.58l-0.05,-2.87l-16.01,1.65l1.74,-4.93l-4.02,-1.42l-7.07,3.76l0,0l2.59,-11.21l7.79,1.43l-2.72,-3.48l1.48,-0.02l-5.54,-4.62l-3.3,9.35l-0.63,-1.04l4.34,-49.39L171.04,344.59z"/>
            </a>
            <a xlink:href="/departments/34" title="Hérault">
                <path id="loc-34" class="land" d="M354.57,440.54l2.73,0.13l0.32,2.8l3.36,-1.93l0.64,2.41l2.1,0.64l4.37,-6.28l4.27,-0.8l1.67,1.75l-0.98,3.28l4.67,-0.52l2.62,2.61l-0.62,2.26l1.76,-0.73l5.67,4.55l2.09,5.43l-1.78,3.22l-2.92,-0.27l0.77,2.05l0,0l-8.07,2.23l-10.84,7.54l-5.27,6.07l-7.02,-0.17l-4.47,3.79l0,0l-1.93,-2.12l-7.56,-1.39l-0.27,-2.61l-4.74,-0.46l-0.95,-3.08l0.06,2.93l-2.59,0.58l-1.42,3.54l-2.74,-0.93l-0.76,-2.77l-2.93,2.2l-3.26,-3.5l2.23,-3.28l-0.88,-0.95l0,0l3.98,-2.84l0.26,-2.62l-2.44,-2.89l1.25,-5.35l4.54,2.62l6.37,-2.37l1.13,-2.21l0,0l5.23,0.07l0.14,-8.15l5.95,1.34l2.35,-4.73L354.57,440.54z"/>
            </a>
            <a xlink:href="/departments/35" title="Ille-et-Vilaine">
                <path id="loc-35" class="land" d="M127.44,157.11l2.69,0.29l1.65,4.49l0,0l-1.54,1.56l-3.35,-3.61l0,0l-1.11,-2.1L127.44,157.11zM138.57,153.19l-1.17,3.75l1.49,2.04l10.96,-1.32l0,0l3.46,9.09l2.79,1.76l2.8,-0.5l4.16,-4.69l7.63,2.23l0,0l1.03,11.19l-2.35,4.09l3.55,16.12l-5.75,2.37l-3.44,9.57l0,0l-0.36,2.05l0,0l-9.63,-3.62l0.24,1.99l-6.19,2.43l-1.49,3.29l-8.39,0.09l-3.94,2.29l-0.76,-1.38l-5.19,3.85l0,0l-1.29,-5.65l2.97,-0.97l-2.88,-1.96l3.83,-3.87l-0.77,-2.19l-2.33,1.26l1.31,-4.39l-2.75,-3.97l-5.99,-0.44l1.05,-3.01l3.94,-2.24l-3.62,0.78l-1.31,-4.42l0,0l2.13,-0.6l2.15,-6.54l3.2,-0.12l2.18,-2.87l4,0.34l-0.44,-3.16l1.66,-0.29l-0.98,-5.94l1.74,-2.43l-0.29,-3.37l-1.42,-0.14l0,0l-3.3,-7.04l3.98,-3.22L138.57,153.19z"/>
            </a>
            <a xlink:href="/departments/36" title="Indre">
                <path id="loc-36" class="land" d="M270.22,247.16L275.29,243.89L278.72,245.09L278.43,242.91L282.03,241.49L284.34,242.73L286.37,241.57L291.55,245.04L291.55,245.04L291.65,247.56L288.84,250.55L293.03,252.21L297.94,250.73L298.32,252.69L300.18,251.9L299.33,255.52L302.62,258.63L301.12,262.46L304.59,263.79L301.31,268.45L299.94,268.13L303.01,271.73L300.95,273.49L301.76,275.8L306,280L304.72,285.24L306.65,289.59L304.47,291.13L305.13,293.16L305.13,293.16L291.47,293.08L289.14,291.23L286.9,295.21L285.1,293.52L282.73,295.34L281.94,293.34L279.26,295.07L278.47,293.02L273.88,297.86L273.88,297.86L271.33,294.64L269.24,296.34L264.03,295.53L264.03,295.53L265.43,292.6L262.89,291.71L262.71,288.28L252.92,282.54L253.61,276.73L251.15,273.68L251.15,273.68L257.1,273.05L256,270.29L258.92,258.68L263.62,255.98L267.17,257.07L271.74,251.29z"/>
            </a>
            <a xlink:href="/departments/37" title="Indre-et-Loire">
                <path id="loc-37" class="land" d="M240.72,215.95L251.13,216.25L250.92,221.87L253.31,219.83L256.28,220.58L256.52,222.78L258.02,221.3L259.85,223.48L258.5,225.97L260.24,230.22L262.19,231.05L260.14,232.21L261.73,234.58L260.98,239.81L263.4,241.79L266.75,240.95L270.22,247.16L270.22,247.16L271.74,251.29L267.17,257.07L263.62,255.98L258.92,258.68L256,270.29L257.1,273.05L251.15,273.68L251.15,273.68L248.19,267.54L244.38,264.3L243.85,260.02L238.71,258.06L239.98,261.03L228.58,262.64L227.73,255.31L222.56,254.83L222.7,251.41L218.48,251.17L217.39,248.44L217.39,248.44L218.34,241.28L222.7,235.17L221.48,234.09L224.33,227.92L223.19,225.53L224.62,221.25L224.62,221.25L230.86,223.74L231.06,219.13L233.8,220.63z"/>
            </a>
            <a xlink:href="/departments/38" title="Isère">
                <path id="loc-38" class="land" d="M426.95,329.63L431.99,332.29L437.33,325.49L448.63,341.49L448.63,341.49L453.37,351.73L460.4,354.69L460.64,349.57L462.79,348.67L466.24,351.92L469.64,352.06L472.29,356.85L469.61,360.8L470.16,368.53L474.57,368.72L475.02,370.17L475.02,370.17L472.69,376.64L477.65,377.66L478.97,386.07L469.77,385.52L466.55,388.34L465.13,387.1L462.11,388.99L463.38,390.35L462.17,391.58L457.02,391.64L455.85,394.73L455.85,394.73L449.56,393.67L445.48,389.56L445.72,391.17L441.71,389.3L442.75,372.68L438.69,375.56L431.45,372.68L428.34,373.27L430.43,370.54L429.61,365.8L431.08,364.82L427.72,363.07L428.42,360.15L425.85,361.05L422.25,357.41L414.48,360.17L414.48,360.17L412.6,356.09L412.6,356.09L412.65,350.82L412.65,350.82L417.43,346.44L413.42,343.05L424.27,341.44L425.18,338.41L429.16,336.23L425.46,332.31z"/>
            </a>
            <a xlink:href="/departments/39" title="Jura">
                <path id="loc-39" class="land" d="M444.23,239.95L446.67,243.01L451.79,242.27L451.79,242.27L457.04,249.87L454.63,252.67L455.26,255.05L453.23,255.07L453.55,257.13L456.29,255.6L457.86,258.29L460.99,257.44L465.54,267.53L470.48,269.22L472.77,272.7L467.12,277.23L468.58,279.49L466.24,282.16L469.94,285.16L469.94,285.16L466.89,293.67L466.89,293.67L458.79,302.74L452.8,302.97L452.32,300.07L449.38,297.95L445.06,302.51L442.35,302.76L442.42,299.69L440.3,297.75L439.45,299.99L438.34,295.69L435.11,293.84L435.75,291.85L435.75,291.85L440.01,290.01L440.17,288.48L437.61,287.44L437.72,283.93L439.47,283.71L441.01,280.34L437.65,274.79L438.92,272.34L436.27,269.62L441.92,268.88L441.84,267.28L439.26,264.96L436.36,265.26L433.16,261.83L433.33,259.65L433.33,259.65L435.91,257.65L434.48,255.45L438.68,253.44L440.91,249.76z"/>
            </a>
            <a xlink:href="/departments/40" title="Landes">
                <path id="loc-40" class="land" d="M163.05,408.61L170.12,404.85L174.15,406.27L172.41,411.2L188.42,409.55L188.47,412.42L199.15,419.01L198.92,423.61L205.85,423.77L205.81,420.29L207.27,419.8L209.39,422.46L209.39,422.46L209.9,426.74L220.88,428.38L217.7,434.11L218.27,436.56L218.27,436.56L217.58,441.56L214.26,439.67L215.42,438.24L213.63,436.5L211.16,439.7L207.69,439.08L205.2,441.48L207.42,443.35L207.11,446.5L205.74,446.6L207.06,450.66L203.43,456.08L205.06,459.38L205.06,459.38L196.48,461.38L196.49,458.72L190.37,462.07L187.81,461.03L188.46,459.97L185.3,461.92L183.17,459.71L167.61,464.96L166.47,463.92L168.13,463.16L165.97,461.55L161.46,464.34L151.85,462.54L151.85,462.54L155.07,455.75z"/>
            </a>
            <a xlink:href="/departments/41" title="Loir-et-Cher">
                <path id="loc-41" class="land" d="M250.04,190.74L254.56,191.06L258.34,188.96L256.36,191.54L261.34,192.1L264.81,199.15L272.01,199.99L275.33,196.74L278.28,198.27L278.28,198.27L280.1,197.8L278.35,201.38L281.19,204.65L278.48,208.25L281.58,213.09L280.65,214.08L282.82,212.07L286.27,213.61L287.38,218.09L289.6,219.56L292.29,216.15L296.67,218.31L306.58,217L308.06,220.56L308.06,220.56L303.13,222.87L303.49,224.82L306.4,225.16L306.34,228.73L308.43,228.44L307.72,233.6L305.99,231.73L302.93,232.77L302.17,236.14L304.72,240.28L299.18,242.53L295.74,240.85L293.8,242.9L294.07,245.45L291.55,245.04L291.55,245.04L286.37,241.57L284.34,242.73L282.03,241.49L278.43,242.91L278.72,245.09L275.29,243.89L270.22,247.16L270.22,247.16L266.75,240.95L263.4,241.79L260.98,239.81L261.73,234.58L260.14,232.21L262.19,231.05L260.24,230.22L258.5,225.97L259.85,223.48L258.02,221.3L256.52,222.78L256.28,220.58L253.31,219.83L250.92,221.87L251.13,216.25L240.72,215.95L240.72,215.95L239.28,214.84L244.27,211.55L247.32,206.96L246.71,203.32L248.81,204.08L250.37,200.89L250.09,195.35L248.08,194.2z"/>
            </a>
            <a xlink:href="/departments/42" title="Loire">
                <path id="loc-42" class="land" d="M377,302.07L376.64,305.75L380.06,306.45L380.92,308.27L389.81,306.94L392.62,309.22L396.59,307.53L397.29,305.4L397.29,305.4L399.29,308.96L394.58,310.79L393.8,314.38L391.56,315.62L394.19,318.23L391.34,318.88L397.65,326.92L395.67,332.23L397.9,333.74L396.37,338.1L400.71,343.12L406.74,343.73L407.85,345.62L409.43,344.58L408.32,348.92L411.21,348.51L412.65,350.82L412.65,350.82L412.6,356.09L412.6,356.09L406.75,359.27L406.17,362.87L401.23,363.73L401.23,363.73L399.29,361.45L395.7,361.45L395.58,356.28L392.51,356.54L390.89,354.71L387.81,354.71L381.97,358.32L380.23,355.51L377.4,358.01L376.96,356.59L376.96,356.59L376.72,354.1L379.7,351.96L380.59,348.43L368.3,330.96L371.02,325.33L368.46,322.71L368.46,322.71L369.08,320.13L374.17,318.6L371.55,304.27z"/>
            </a>
            <a xlink:href="/departments/43" title="Haute-Loire">
                <path id="loc-43" class="land" d="M344.32,356.93L347.13,356.96L352.13,352.86L357.12,354.17L360.47,352.39L365.33,357.72L367.5,355.04L371.87,357.12L372.99,354.58L376.96,356.59L376.96,356.59L377.4,358.01L380.23,355.51L381.97,358.32L387.81,354.71L390.89,354.71L392.51,356.54L395.58,356.28L395.7,361.45L399.29,361.45L401.23,363.73L401.23,363.73L400.18,370.61L396.71,369.15L397.75,371.43L395.56,373.6L397.07,375.17L393.5,376.45L394.22,379.16L390.53,379.74L387.69,385.01L382.63,385.05L382.37,387.31L379.09,387.7L378.02,391.1L375.44,392.52L375.44,392.52L370.53,387.04L367.34,387.59L365.82,384.52L364.31,384.84L364.1,387.74L359.51,388.71L356.45,380.09L354.66,379.27L354.66,379.27L351.41,371.52L354.63,371.5L351.66,370.51L348.91,361.34L343.08,360.62z"/>
            </a>
            <a xlink:href="/departments/44" title="Loire-Atlantique">
                <path id="loc-44" class="land" d="M128.02,219.89l5.19,-3.85l0.76,1.38l3.94,-2.29l8.39,-0.09l1.49,-3.29l6.19,-2.43l-0.24,-1.99l9.63,3.62l0,0l-0.32,2.8l2.44,0.57l2.38,6.28l5.48,1.87l-1.52,1.62l-5.4,-0.59l0.66,3.84l7.86,1.08l1.87,5.67l-2.22,1.74l-13.06,1.9l-2.61,2.17l2.42,-0.1l2.23,3.81l1.98,-0.52l1.26,4.34l-3.51,4.96l5.47,2.22l-1.27,2.08l0,0l-5.11,-3.43l-1.24,3.08l-3.23,0.14l0.56,4.51l-4.07,1.85l-0.37,-6.31l-1.3,-0.47l-2.2,1.52l2.29,8.09l-1.93,1.22l-7.23,-1.43l-0.98,-2.69l-6.41,-2l-3.28,-4.13l0,0l-3.07,-3.98l-8.07,-2.34l3.36,-2.17l-0.21,-5.83l-5.21,1.82l-3.26,-2.68l-2.33,1.22l-4.7,-1.94l1.63,-2.93l-2.23,-2.2l4.2,-4.51l0,0l1.43,-1.77l4.4,0.97l0.78,-3.38l5.6,1.62l0.26,-1.95l2.46,-0.69L128.02,219.89z"/>
            </a>
            <a xlink:href="/departments/45" title="Loiret">
                <path id="loc-45" class="land" d="M297.89,179.38L304.93,178.63L306.57,175.79L308.32,178.63L311.81,176.5L314.93,177.56L314.93,177.56L315.74,181.04L319.15,182.38L319.86,184.74L316.57,189.46L327.44,189.49L329.46,188.13L328.72,186.8L331.32,186.68L332.17,189.09L337.05,187.03L337.05,187.03L340.89,188.88L341.73,192.6L344.84,195.29L344.98,198.82L340.05,202.94L340.72,210.3L333.51,212.12L333.35,214.61L336.6,216.77L338.69,223.68L338.69,223.68L333.72,224.72L334.45,226.65L334.45,226.65L331.65,228.23L328.52,225.57L328.22,228.27L325.88,228.89L321.52,223.52L318.54,223.75L316.32,221.21L313.59,222.69L310.17,220.02L308.06,220.56L308.06,220.56L306.58,217L296.67,218.31L292.29,216.15L289.6,219.56L287.38,218.09L286.27,213.61L282.82,212.07L280.65,214.08L281.58,213.09L278.48,208.25L281.19,204.65L278.35,201.38L280.1,197.8L278.28,198.27L278.28,198.27L277.9,195.49L281.33,195.27L282.57,192.82L287.55,193.22L293.28,191.09L294.14,187.24L296.98,186.41z"/>
            </a>
            <a xlink:href="/departments/46" title="Lot">
                <path id="loc-46" class="land" d="M275.25,376.42L278.99,374.84L283.49,375.93L289.95,382.12L294.35,378.91L296,380.39L300.7,379.01L300.7,379.01L301.93,384.98L305.27,389.93L303.53,395.28L306.77,399.92L306.77,399.92L306.33,401.78L300.61,401.91L294.77,407.51L293.56,406.45L291.56,407.86L294.55,415.02L293.29,416.06L293.29,416.06L285.69,417.57L284.03,419.35L283.03,418.36L283.31,420.05L280.41,418.2L280.99,421.36L279.1,422.45L276.24,418.49L271.62,423.89L268.42,422.03L269.25,418.61L265.55,420.16L260.06,415.28L262.33,412.94L259.36,413.77L259.36,413.77L255.97,404.21L259.9,402.48L259.9,402.48L262.87,396.9L269.82,392.82L269.1,389.57L274.92,384.83L273.67,377.23z"/>
            </a>
            <a xlink:href="/departments/47" title="Lot-et-Garonne">
                <path id="loc-47" class="land" d="M209.39,422.46L213.86,419.85L211.99,415.07L216.25,413.84L214.63,411.38L215.3,403.71L218.11,403.78L222.88,397.3L219.36,394.98L222.99,392.03L224.26,393.65L224.63,391.26L227.55,391.4L227.55,391.4L229.99,397.69L238.11,397.06L241.22,394.72L243.63,396.5L248.28,395.05L250.59,396.92L249.81,400.91L255.91,398.36L259.9,402.48L259.9,402.48L255.97,404.21L259.36,413.77L259.36,413.77L254.28,415.68L252.41,413.6L251.29,417.71L254.66,419.76L252.83,424.58L250.62,424.96L252.31,427.62L247.96,427.29L245.95,431.77L245.95,431.77L242.7,434.15L239.76,430.93L227.61,436.11L224.03,434.27L221.63,437.14L220.53,435.49L218.27,436.56L218.27,436.56L217.7,434.11L220.88,428.38L209.9,426.74z"/>
            </a>
            <a xlink:href="/departments/48" title="Lozère">
                <path id="loc-48" class="land" d="M354.66,379.27L356.45,380.09L359.51,388.71L364.1,387.74L364.31,384.84L365.82,384.52L367.34,387.59L370.53,387.04L375.44,392.52L375.44,392.52L377.17,401.23L379.72,403.52L381.13,409.04L381.13,409.04L379.09,412.49L376.45,413.22L379.21,416.43L378,417.97L380.15,420.7L378.57,424.32L380.11,426.2L378.01,425.05L376,428.3L372.75,428.23L367.54,424.83L365.92,428.59L362.19,429.01L355.11,425.77L355.11,425.77L354.67,424.02L348.13,424.62L349.23,422.29L346.35,421.47L345.99,419.71L344.69,420.45L345.67,410.28L342.43,406.54L343.22,403.27L338.91,398.32L338.91,398.32L343.96,384.23L345.57,383.27L347.63,385.61L349.7,381.34L351.52,381.85z"/>
            </a>
            <a xlink:href="/departments/49" title="Maine-et-Loire">
                <path id="loc-49" class="land" d="M163.72,208.91L174.42,211.89L176.44,209.89L184.54,213.34L192.98,212.58L193.63,210.27L199.26,211.96L199.26,211.96L200.05,214.77L207.87,214.64L206.09,216.75L209.41,219.76L211.26,217.95L221.13,222.95L222.81,220.81L224.62,221.25L224.62,221.25L223.19,225.53L224.33,227.92L221.48,234.09L222.7,235.17L218.34,241.28L217.39,248.44L217.39,248.44L214.81,248.68L213.6,253.17L211.52,252.3L210.86,254.52L210.86,254.52L207.84,254.35L209.21,252.23L207.46,251.84L198.64,252.88L195.13,255.17L196.03,253.46L194.58,253.34L192.02,254.52L192.55,256.44L190.25,258.6L182.44,258.09L178.1,259.89L178.1,259.89L174.61,257.32L171.34,258.17L167.5,256.69L167.5,256.69L168.77,254.61L163.3,252.4L166.81,247.43L165.55,243.09L163.58,243.61L161.34,239.8L158.93,239.9L161.54,237.73L174.6,235.83L176.82,234.09L174.95,228.42L167.09,227.33L166.43,223.5L171.83,224.09L173.35,222.47L167.87,220.6L165.49,214.32L163.05,213.76L163.37,210.96L163.37,210.96z"/>
            </a>
            <a xlink:href="/departments/50" title="Manche">
                <path id="loc-50" class="land" d="M135.38,88.47l13.17,5.19l4.39,-1.29l0.98,-1.99l8.64,0.05l1.5,5.71l-2.86,1.58l-0.35,2.75l5.8,8.42l-0.62,3.71l2.71,0.3l0,0l-0.8,4.63l2.4,2.31l4.32,2.73l2,-2.59l1.6,1.94l-2.98,2.46l2.98,2.08l-1.49,1.72l1.83,-0.9l0.78,5.5l-3.52,4.06l-4.64,0.48l-0.81,2.22l2.26,-0.28l0.12,1.3l-5.8,4.99l3.18,3.1l5.45,-0.71l2.84,2.07l2.53,-0.99l-0.75,1.23l0,0l-0.42,1.36l4.75,2.8l-1.55,1.79l1.42,2.26l-5.03,7.47l0,0l-8.7,-0.41l0,0l-7.63,-2.23l-4.16,4.69l-2.8,0.5l-2.79,-1.76l-3.46,-9.09l0,0l9.05,0.14l-2.41,-1.68l1.05,-1.03l-2.54,1.28l-5.05,-5.76l-0.18,-4.7l-1.66,-0.84l2.94,-6.04l-0.59,-6.02l2.2,0.38l-2.34,-0.98l-0.72,2.27l-0.9,-2.41l-0.62,-11.37l1.63,-0.32l-1.64,-0.7l-0.51,1.57l-3.52,-7.38l-4.08,-2.99l-3.24,-10.28l1.96,-2.35l-0.33,-3.85l-4.1,-2.8L135.38,88.47z"/>
            </a>
            <a xlink:href="/departments/51" title="Marne">
                <path id="loc-51" class="land" d="M359.78,144L367.78,133.8L366.66,131.97L363.94,132.36L366.14,129.82L364.59,127.14L365.48,125.19L370.72,124.66L366.8,121.24L366.44,114.85L371.95,112.13L375,112.86L375.18,110.61L378.01,108.92L382.7,111.88L383.18,108.95L383.18,108.95L391.45,110.42L393.97,114.04L396.96,114.14L399.73,117.42L406.01,116.23L407.51,120.02L409.84,118.38L417.14,120.28L417.59,118.05L419.53,117.84L420.67,119.68L420.67,119.68L422.36,121.39L420.18,122.99L422.92,130.94L421.26,133.32L424.21,133.19L422.47,135.7L424.34,136.33L419.09,141.06L420.11,144.6L418.17,147.36L423.13,150.92L422.24,154.51L422.24,154.51L413.61,155.42L413.24,156.55L416.63,157.65L416.63,159.05L412.87,160.16L414.34,164.26L408.97,164.13L408.97,164.13L405.76,162.76L403.79,164.5L397.88,162.26L394.35,158.88L395.04,154.92L393.64,152.65L386.67,154.4L384.53,153.46L377.04,161.41L375.03,161.05L375.58,163.72L373.93,165.14L366.22,163.69L362.86,158.75L362.86,158.75L361.81,156.89L358.36,157.59L359.07,151.15L356.01,149.88L358.04,147.88L356.38,146.73L359.86,146.4z"/>
            </a>
            <a xlink:href="/departments/52" title="Haute-Marne">
                <path id="loc-52" class="land" d="M408.97,164.13L414.34,164.26L412.87,160.16L416.63,159.05L416.63,157.65L413.24,156.55L413.61,155.42L422.24,154.51L422.24,154.51L422.77,158.95L425.1,157.79L425.68,160.07L427.67,160.1L431.52,164.3L436.34,165.47L442.17,171.01L442.17,171.01L439.03,172.85L441.04,173.59L440.41,176.58L444.47,175.55L453.06,185.1L450.95,186.05L450.54,190.46L448.77,191.88L454.95,195.09L455.4,200.09L457.87,198.66L459.44,201.73L459.44,201.73L457.08,206.27L454.29,205.82L453.5,208.25L451.4,208.2L451.39,216.58L447.49,217.35L446.27,215.18L444.53,217.3L439.75,217.21L438.17,221.5L438.17,221.5L435.45,221.23L433.17,223.24L433.49,220.45L429.93,218.61L430.14,216.88L427.61,218.96L423.91,215.02L421.41,216.52L421.01,211.84L419.21,211.52L422.45,208.28L418.69,202.01L415.72,202.77L417.12,200.82L413.77,199.46L414.12,196.77L410.46,195.84L410.46,195.84L411.2,194.33L408.49,192.24L411.54,189.87L415.84,190.62L416.95,180.64L414.95,177.11L416.05,175.93L410.49,172.89L407.27,168.49z"/>
            </a>
            <a xlink:href="/departments/53" title="Mayenne">
                <path id="loc-53" class="land" d="M179.4,165.97L183.35,170.02L187.37,166.92L188.06,169.56L193.94,165.51L198.64,165.45L200.45,167.06L201.78,164.67L203.85,165.61L204.77,162.04L206.51,162.09L209.08,164.31L207.91,165.4L209.03,168.75L213.02,169.11L212.79,173.48L212.79,173.48L208.57,176.04L209.09,184.45L204.6,187.38L205.52,193.51L200.95,194.54L203.64,199.77L198,202.36L199.71,205.91L196.64,207.55L199.09,209.34L199.26,211.96L199.26,211.96L193.63,210.27L192.98,212.58L184.54,213.34L176.44,209.89L174.42,211.89L163.72,208.91L163.72,208.91L167.16,199.34L172.91,196.97L169.37,180.85L171.72,176.75L170.7,165.56L170.7,165.56z"/>
            </a>
            <a xlink:href="/departments/54" title="Meurthe-et-Moselle">
                <path id="loc-54" class="land" d="M455.14,99.23L459.6,103.12L459.6,103.12L461.85,105.1L460.5,108.87L463.28,112.85L462,114.07L464.4,115.01L465.92,119.49L463.25,122.4L465.55,124.19L463.46,125.54L464.37,127.6L461.08,128.89L466.06,133.88L465.96,136.13L469.03,136.7L469.7,138.79L477.11,139.65L476.04,144.36L478.32,145.15L476.72,145.76L478.97,146.52L479.07,148.57L482.18,147.94L487.57,150.01L489.08,152.59L491.71,153.18L491.92,155.57L492.7,154.48L499.64,158.46L502.81,157.59L506.15,159.13L505.45,160.23L508.89,163.86L508.89,163.86L510.62,165.21L510.62,165.21L496.69,173.04L490.99,170.55L490.01,167.76L487.77,169.75L488.27,171.3L486.38,170.33L480.37,172.84L476.49,170.14L473.91,172.4L470.48,171.74L469.06,175.19L467.86,173.31L467.19,174.8L462.64,175.42L462.15,172.54L460.26,172.28L461.38,170.2L458.25,171.69L460.29,166.4L454.46,166.27L454.46,166.27L454.95,163.56L452.42,160.53L456.03,158.26L454.19,158.47L454.5,153.5L452.43,150.72L455.06,147.58L455.5,141.83L453.72,139.89L457.97,137.68L455.95,135.54L458.15,132.02L456.14,130.65L456.8,127.9L453.71,126.79L454.46,122.2L452.78,122.29L452.69,117.91L454.71,115.26L453.15,115.03L452.23,111.64L453.23,109.45L448.98,106.89L442.79,110.38L441.38,109.26L443.73,107.98L440.56,103.77L442.35,103.1L442.35,103.1L445.81,101.06L448.09,102.51L449.37,99.78L453.67,100.5z"/>
            </a>
            <a xlink:href="/departments/55" title="Meuse">
                <path id="loc-55" class="land" d="M440.3,96.62L442.35,103.1L442.35,103.1L440.56,103.77L443.73,107.98L441.38,109.26L442.79,110.38L448.98,106.89L453.23,109.45L452.23,111.64L453.15,115.03L454.71,115.26L452.69,117.91L452.78,122.29L454.46,122.2L453.71,126.79L456.8,127.9L456.14,130.65L458.15,132.02L455.95,135.54L457.97,137.68L453.72,139.89L455.5,141.83L455.06,147.58L452.43,150.72L454.5,153.5L454.19,158.47L456.03,158.26L452.42,160.53L454.95,163.56L454.46,166.27L454.46,166.27L452.52,168.6L449.35,167.81L448.49,170.7L442.17,171.01L442.17,171.01L436.34,165.47L431.52,164.3L427.67,160.1L425.68,160.07L425.1,157.79L422.77,158.95L422.24,154.51L422.24,154.51L423.13,150.92L418.17,147.36L420.11,144.6L419.09,141.06L424.34,136.33L422.47,135.7L424.21,133.19L421.26,133.32L422.92,130.94L420.18,122.99L422.36,121.39L420.67,119.68L420.67,119.68L424.8,117.33L423.85,113.44L426.9,110.72L425.9,109.17L427.32,105.65L425.16,102.93L427.38,97.09L433.6,100.31L439.14,95.54L439.14,95.54z"/>
            </a>
            <a xlink:href="/departments/56" title="Morbihan">
                <path id="loc-56" class="land" d="M80.29,234.83l7.73,4.48l-0.56,1.7l-6.78,-1L80.29,234.83zM67.12,185.58l4.98,-0.08l1.15,2.61l3.38,-1.55l2.66,1.93l5.48,-1.5l1.03,-2.66l3.23,0.19l1.56,2.71l8.12,0.96l1.27,3.59l0.8,-1.83l3.91,-0.88l0.17,6.11l4.49,-2.74l0.54,-5.04l4.61,-1.01l2.77,3.88l2.91,-1.44l0,0l1.31,4.42l3.62,-0.78l-3.94,2.24l-1.05,3.01l5.99,0.44l2.75,3.97l-1.31,4.39l2.33,-1.26l0.77,2.19l-3.83,3.87l2.88,1.96l-2.97,0.97l1.29,5.65l0,0l0,6.03l-2.46,0.69l-0.26,1.95l-5.6,-1.62l-0.78,3.38l-4.4,-0.97l-1.43,1.77l0,0l-1.56,-0.12l-0.26,-2.58l2.52,-0.38l-2.85,-1.59l-12.7,2.33l-8.69,-6.49l0.04,1.2l-4.13,0.2l1.37,5.91h-1.69l-1.13,-8.19l-8.61,-6.4l-3.96,0.74l-3.04,-3.97l0,0l0.25,-5.07l1.56,1.35l1.74,-2.46l2.06,0.04l0.29,-5.87l-1.04,-0.99l-2.1,1.75l-2.65,-2.67l-4.48,0.25l-3.96,-7.85l0.83,-1.91L67.12,185.58z"/>
            </a>
            <a xlink:href="/departments/57" title="Moselle">
                <path id="loc-57" class="land" d="M474.44,102.38L486.73,107.3L489.16,111.53L487.76,112.71L492.03,116.97L494.9,124.3L498.92,125L499.03,121.23L503.19,120.59L507.14,122.63L507.86,127.49L509.84,124.86L512.24,127.1L517.92,127.45L520.87,123.88L524.18,123.11L523.81,124.31L526.23,124.04L527.97,128.81L532.17,131.3L532.17,131.3L528.02,138.94L524.5,136.67L519.26,138.25L517.94,136.33L510.96,134.31L510.57,130.3L508.51,130.56L507.05,137.45L503.38,138.89L503.83,141.4L509.66,143.35L507.83,144.51L508.96,148.25L513.39,144.19L518.79,149.33L515.79,154.05L518.37,156.06L516.73,160.41L512.58,164.35L508.89,163.86L508.89,163.86L505.45,160.23L506.15,159.13L502.81,157.59L499.64,158.46L492.7,154.48L491.92,155.57L491.71,153.18L489.08,152.59L487.57,150.01L482.18,147.94L479.07,148.57L478.97,146.52L476.72,145.76L478.32,145.15L476.04,144.36L477.11,139.65L469.7,138.79L469.03,136.7L465.96,136.13L466.06,133.88L461.08,128.89L464.37,127.6L463.46,125.54L465.55,124.19L463.25,122.4L465.92,119.49L464.4,115.01L462,114.07L463.28,112.85L460.5,108.87L461.85,105.1L459.6,103.12L459.6,103.12L462.82,103.4L463.38,106.07L465.95,106.33L471.45,102.37z"/>
            </a>
            <a xlink:href="/departments/58" title="Nièvre">
                <path id="loc-58" class="land" d="M338.69,223.68L340.55,224.45L343.9,222.55L346.49,226.78L354.07,229.82L355.96,227.57L359.94,228.3L360.39,224.19L364.05,230.61L367.68,230.97L369.83,234.14L372.36,233.73L373.62,235.33L375.66,231.91L375.91,236.13L379.08,234.38L381.3,239.52L385.62,237.73L385.62,237.73L387.14,236.8L386.75,243.91L388.62,242.93L390.67,245.71L389.92,248.96L389.92,248.96L389.02,249.26L389.02,249.26L386.01,249.54L386.08,250.86L386.08,250.86L383.29,251.57L383.57,257.59L380.94,259.31L382.79,259.31L382.75,263.9L385.34,266.45L383,268.3L383.46,271.86L372.76,276.48L370.29,273.4L365.78,273.6L365.78,273.6L364.42,272.61L364.44,275.1L362.39,275.19L362.94,277.23L359.68,279.56L357.12,275.72L354.06,277.53L350.83,275.54L348.05,278.03L340.96,270.84L340.96,270.84L342.89,256.97L340.4,254.51L338.9,242.49L334.17,237.81L336.79,231.72L334.45,226.65L334.45,226.65L333.72,224.72z"/>
            </a>
            <a xlink:href="/departments/59" title="Nord">
                <path id="loc-59" class="land" d="M342.28,59.77l0.66,0.14l0,0l-0.22,2.55l-2.59,1.22l-0.33,-2.45l2.3,-1.1l0,0L342.28,59.77zM321.47,2.24l2.94,7.09l-1.73,1.82l0.39,4.6l1.48,2.44l3.64,0.14l2.75,5.49l4.85,2.26l1.91,-3.91l8.24,-2.49l1.66,4.24l2.88,1.54l-0.89,2.85l2.17,8.71l3.51,2.16l4.1,-2.73l1.83,0.63l-0.76,2.36l4.48,-0.57l2.16,2.56l0.03,5.86l1.97,3.96l1.64,-2.99l6.42,1.57l5.09,-2.08l4.7,6.55l1.03,-2.02l1.92,1.11l-3.32,8.96l2.98,0.04l1.25,4.11l-2.91,1.36l-0.76,4.41l0,0l-6.75,-0.88l0.07,-3.27l-3.93,2.24l-0.64,-1.82l-6.54,-2.08l-2.29,2.57l-4.79,-1.62l-2.2,2.36l-5.28,-1.2l-7.93,1.63l0,0l-3.43,-2.73l0,0l0.06,-4.34l1.58,-1.07l-1.2,-1.56l3.63,-4.33l-1.65,-2.16l-5.69,-0.47l3.08,-2.66l-4.19,-5.95l3.51,-1.53l0.05,-2.01l-2.24,0.9l0.52,-2.66l-2.5,-1.76l-2.81,0.7l-0.58,-2.4l-3.48,0.23l-0.53,-3.4l3.31,-2.98l-2.49,-2.47l-1.78,0.38l0.97,1.91l-1.88,1.96l-1.38,-1.8l-9.37,-0.05l-3.52,-3.22l-1.2,0.62l-1.43,-4.44l3.01,-1.72l-5.06,-0.73l-3.13,-2.47l-5.24,-12.62l0,0l3.44,-0.91l0.5,1.18l0.47,-1.62L320.75,0L321.47,2.24z"/>
            </a>
            <a xlink:href="/departments/60" title="Oise">
                <path id="loc-60" class="land" d="M286.29,108.89L289.46,102.75L286.4,102.67L287.72,100.55L285.29,97.88L286.59,97.31L285.59,93.77L287.84,90.53L285.24,90.53L287.75,86.14L289.24,86.52L289.24,86.52L291.51,87.66L291.86,90.11L296.96,88.95L300.88,91.31L305.73,89.93L311.28,90.85L318,95.27L319.17,94.09L321.87,96.8L324.14,95.93L325.17,98.4L326.78,94.96L330.81,95.82L331.25,92.7L335.2,91.94L334.83,89.29L337.77,92.4L338.75,89.82L340.81,91.49L341.83,89.31L343.12,90.79L344.66,89.81L344.66,89.81L343.54,95.15L345.41,98.19L343.58,102.12L344.13,105.11L346.53,106.78L343.58,107.21L341.53,113.88L338.4,113.98L339.11,116.45L341.28,116.56L341.36,120.01L337.97,120.39L340.6,123.93L339.98,121.06L343.16,122.54L343.34,124.65L343.86,122.21L345.78,124.58L342.66,127.33L342.66,127.33L342.43,129.31L339.15,130.2L338.37,128.91L333.51,130.25L331.7,128.58L329.37,130.83L327.06,130.63L324.42,127.85L322.65,129.65L322.65,129.65L321.15,126.86L318.5,127.99L311.17,122.94L307.58,125.14L307.22,123.39L305.23,124.39L301.51,121.43L298.23,123.61L291.31,124.35L287.42,123.28L287.35,120.55L285.85,120.07L285.85,120.07L286.09,117.98L288.11,117.49L289.4,119.16L290.02,117.35z"/>
            </a>
            <a xlink:href="/departments/61" title="Orne">
                <path id="loc-61" class="land" d="M180.23,150.29L185.47,147.27L186.34,144.34L191.34,145.4L191.34,145.4L198.15,142.83L198.15,142.83L200.73,145.88L202.44,143.66L209.89,146.27L208.96,145.04L212.29,144.77L220.34,137.77L221.54,139.47L226.64,136.79L232.34,137.85L232.34,137.85L231.09,140.22L232.69,141.83L240.71,142.39L240.46,145.26L246.58,149.33L247.24,151.2L245.33,153.13L248.99,155.44L248.99,155.44L249.13,159.07L255.25,164.6L253.98,167.54L255.64,169.8L253.07,174.02L247.09,177.16L249.59,183.93L248.24,185.13L248.24,185.13L245.76,185.5L242.26,180.8L241.64,182.71L237.35,181.74L235.45,177.92L231.4,177.19L231,170.76L227.62,167.27L221.13,168.72L217.53,173.69L212.79,173.48L212.79,173.48L213.02,169.11L209.03,168.75L207.91,165.4L209.08,164.31L206.51,162.09L204.77,162.04L203.85,165.61L201.78,164.67L200.45,167.06L198.64,165.45L193.94,165.51L188.06,169.56L187.37,166.92L183.35,170.02L179.4,165.97L179.4,165.97L184.43,158.5L183.01,156.24L184.56,154.45L179.81,151.64z"/>
            </a>
            <a xlink:href="/departments/62" title="Pas-de-Calais">
                <path id="loc-62" class="land" d="M301.54,5.23l0.24,0.14l0,0l5.24,12.62l3.13,2.47l5.06,0.73l-3.01,1.72l1.43,4.44l1.2,-0.62l3.52,3.22l9.37,0.05l1.38,1.8l1.88,-1.96l-0.97,-1.91l1.78,-0.38l2.49,2.47L330.96,33l0.53,3.4l3.48,-0.23l0.58,2.4l2.81,-0.7l2.5,1.76l-0.52,2.66l2.24,-0.9l-0.05,2.01l-3.51,1.53l4.19,5.95l-3.08,2.66l5.69,0.47l1.65,2.16l-3.63,4.33l1.2,1.56l-1.58,1.07l-0.06,4.34l0,0l-5.96,0.04l-3.42,2.11l-0.65,-3.73l-3.93,2.55l0.99,-4.94l-2.86,-0.85l-0.22,3.07l-5.31,-2.07l-0.77,-1.92l-0.99,1.87l-0.77,-1.75l-2.3,0.63l-1.67,2.83l-1.77,-1.43l0.49,-2.84l4.44,-2.64l-1.81,-2.32l-3.85,1.55l-1.09,-2.1l-0.22,1.68l-2.37,-1.02l-5.01,2.49l-2.67,-0.67l-0.37,-3.33l-5.41,-2.31l0.04,-2.8l-1.61,1.27l-4.3,-3.19l-5.8,2l-0.97,-1.44l0,0l-3.64,-3.14l1.34,-8.96l2.01,1.63l-2.44,-3.65l0.2,-19.74l8.45,-5.58L301.54,5.23zM342.1,60.13L342.1,60.13l-2.3,1.1l0.33,2.45l2.59,-1.22l0.22,-2.55L342.1,60.13z"/>
            </a>
            <a xlink:href="/departments/63" title="Puy-de-Dôme">
                <path id="loc-63" class="land" d="M321.71,310L324.68,311.46L328.57,305.26L331.77,306.83L332.7,303.79L336.34,303.56L337.44,304.15L336.09,307.92L340.27,312.42L343.47,311.69L345.04,314.36L353.45,315.85L357.06,314.11L358.84,314.98L358.94,318.08L364.21,317.15L368.46,322.71L368.46,322.71L371.02,325.33L368.3,330.96L380.59,348.43L379.7,351.96L376.72,354.1L376.96,356.59L376.96,356.59L372.99,354.58L371.87,357.12L367.5,355.04L365.33,357.72L360.47,352.39L357.12,354.17L352.13,352.86L347.13,356.96L344.32,356.93L344.32,356.93L339.67,360.56L334.86,355.06L327.82,355.16L326.73,351.86L319.27,349.45L319.27,349.45L319.65,344.98L317.31,342.51L320.16,338.8L318.57,334.08L318.57,334.08L314.28,328.81L318.61,326.63L323.45,320.49L321,313.4z"/>
            </a>
            <a xlink:href="/departments/64" title="Pyrénées-Atlantiques">
                <path id="loc-64" class="land" d="M151.85,462.54l9.61,1.8l4.51,-2.8l2.15,1.61l-1.65,0.76l1.13,1.04l15.56,-5.25l2.13,2.21l3.16,-1.95l-0.66,1.06l2.56,1.03l6.12,-3.35l-0.01,2.66l8.59,-2l0,0l6.06,0.16l0,0l3.24,6.27l-1.97,0.51l-0.06,2.97l2.76,-1.96l1.26,5.64l-3.15,2.55l1.2,1.76l-2.32,2.71l0.22,2.61l-2.06,-0.17l-3.14,3.73l0.54,3.53l-4.49,2.63l-1.06,8.81l0,0l-3.38,2.85l-4.63,-1.6l-1.99,2.68l-6.79,-6.05l-1.51,-4.58l-7.86,0.83l-7.1,-4l-1.16,0.91l-6.92,-3.42l1.59,-2.83l-2.57,0.66l-0.88,4.44l-3.57,-1l-1.37,-2.38l3.79,-5.71l-0.27,-3.9l-6.08,-2.04l-3.04,2.38l-0.66,-3.13l-4.36,0.6l-2.39,-3.1l6.53,-3.56L151.85,462.54zM211.15,471.38L211.15,471.38l0.15,3.66l0.85,-2.9L211.15,471.38zM210.08,478.97l1.73,-1.24l-1.76,-2.23L210.08,478.97z"/>
            </a>
            <a xlink:href="/departments/65" title="Hautes-Pyrénées">
                <path id="loc-65" class="land" d="M210.53,475.05l1.27,2.68l-1.73,1.24L210.53,475.05zM212.04,472.07l0.11,0.07l0,0l-0.85,2.9l-0.64,-3.31l0,0l0,0l0,0l0.49,-0.35l0,0L212.04,472.07zM211.13,459.54l3.41,-1.34l2.79,5l2.67,0.18l2.7,8.27l4.75,-1.04l1.44,2.55l11.46,1.87l0,0l1.15,0.82l-3.5,3.37l0.92,0.91l-2.32,0.43l-3.19,4.66l5.21,3.16l-1.55,3.98l3.3,-1.67l1.59,4.15l-3.5,5.64l-3.54,-0.96l-1.19,8.14l1.12,1.89l0,0l-4.8,-1.36l-2.73,2.85l-4.91,-3.57l-9.67,2.52l-4.29,-5.95l-6.33,-2.9l0,0l1.06,-8.81l4.49,-2.63l-0.54,-3.53l3.14,-3.73l2.06,0.17l-0.22,-2.61l2.32,-2.71l-1.2,-1.76l3.15,-2.55l-1.26,-5.64l-2.76,1.96l0.06,-2.97l1.97,-0.51L211.13,459.54z"/>
            </a>
            <a xlink:href="/departments/66" title="Pyrénées-Orientales">
                <path id="loc-66" class="land" d="M304.95,511.62l1.26,0.7l6.65,-4.3l-0.68,-6.4l16.62,0.29l2.11,-3.43l3.13,-1.24l7.46,4.46l0,0l0.33,16.63l3.72,1.55l1.37,4.55l-3.67,0.52l-1.89,-2.67l-3.88,-0.5l-7.01,3.93l-4.3,0.4l-0.11,3.59l-3.95,-0.99l-1.93,1.43l-4.04,-3.39l-7.31,-2.5l-10.14,4.82l-3.25,-5.66l-8.54,-2.37l0.39,-3.41l2,-1.04l0,0l7.77,-2.42l1.01,-2.49L304.95,511.62z"/>
            </a>
            <a xlink:href="/departments/67" title="Bas-Rhin">
                <path id="loc-67" class="land" d="M508.89,163.86L512.58,164.35L516.73,160.41L518.37,156.06L515.79,154.05L518.79,149.33L513.39,144.19L508.96,148.25L507.83,144.51L509.66,143.35L503.83,141.4L503.38,138.89L507.05,137.45L508.51,130.56L510.57,130.3L510.96,134.31L517.94,136.33L519.26,138.25L524.5,136.67L528.02,138.94L532.17,131.3L532.17,131.3L538.69,130.52L541.69,132.56L544.49,131.02L556.89,136.82L550.96,147.12L546.08,149.96L540.57,157.24L536.15,172.66L536.66,176.85L534.52,178.43L529.69,189.68L529.69,189.68L525.23,187.23L525.52,184.54L518.82,181.55L519.51,180.35L517.89,180.42L517.19,178.23L513.99,177.9L513.99,177.9L513.19,176.07L508.87,175.28L510.62,165.21L510.62,165.21z"/>
            </a>
            <a xlink:href="/departments/68" title="Haut-Rhin">
                <path id="loc-68" class="land" d="M513.99,177.9L517.19,178.23L517.89,180.42L519.51,180.35L518.82,181.55L525.52,184.54L525.23,187.23L529.69,189.68L529.69,189.68L529.39,195.05L531.58,198.9L528.85,204.64L528.49,213.6L526.98,215.84L530.02,222.86L526.45,225.53L527.76,226.24L526.01,229.02L523.64,228.23L524.64,229.71L521.77,232.09L515.83,232.75L512.79,231.37L514.11,228.3L511.23,227.75L511.23,227.75L509.3,222.24L505.95,221.67L507.24,214.26L500.16,210.59L498.91,208.16L498.91,208.16L502.37,206.45L501.41,203.92L503.38,197.19L508.91,190.38L508.19,188.16z"/>
            </a>
            <a xlink:href="/departments/69" title="Rhône">
                <path id="loc-69" class="land" d="M397.29,305.4L398.93,300.47L402.11,302.57L404.5,300.94L406.86,302.69L409.42,300.26L410.69,301.91L409.4,303.09L411.75,304.62L411.32,307.81L413.57,308.03L413.57,308.03L414.51,309.52L412.18,313.25L411.5,322.02L414.77,323.33L414.6,324.73L417.79,324.74L419.33,330.26L426.95,329.63L426.95,329.63L425.46,332.31L429.16,336.23L425.18,338.41L424.27,341.44L413.42,343.05L417.43,346.44L412.65,350.82L412.65,350.82L411.21,348.51L408.32,348.92L409.43,344.58L407.85,345.62L406.74,343.73L400.71,343.12L396.37,338.1L397.9,333.74L395.67,332.23L397.65,326.92L391.34,318.88L394.19,318.23L391.56,315.62L393.8,314.38L394.58,310.79L399.29,308.96z"/>
            </a>
            <a xlink:href="/departments/70" title="Haute-Saône">
                <path id="loc-70" class="land" d="M459.44,201.73L461.45,198.55L462.14,201.12L465.89,196.94L469.69,195.68L472.71,201.36L480.39,199.59L484.08,204.23L489.27,200.5L498.39,208.6L498.39,208.6L495.68,212.74L497.69,224.1L497.69,224.1L496.09,225.61L491.61,222.99L491.14,225.69L488.33,225.17L487.32,228.51L484.97,227.24L483.79,228.73L481.31,226.53L478.13,227.52L474.22,233.24L472.77,232.13L471.25,234.69L469.1,234.26L468.92,236.44L463.37,238.6L461.25,237.37L460.01,239.49L451.79,242.27L451.79,242.27L446.67,243.01L444.23,239.95L444.23,239.95L442.16,239.16L443.42,234.72L440.65,234.52L440.92,230.98L437.94,230.12L442.95,226.27L442.58,221.42L440.47,219.88L438.17,221.5L438.17,221.5L439.75,217.21L444.53,217.3L446.27,215.18L447.49,217.35L451.39,216.58L451.4,208.2L453.5,208.25L454.29,205.82L457.08,206.27z"/>
            </a>
            <a xlink:href="/departments/71" title="Saône-et-Loire">
                <path id="loc-71" class="land" d="M389.92,248.96L392.56,251.88L395.58,252.27L395.92,254.58L397.9,253.41L398.93,256.14L404.2,257.19L403.92,258.91L409.74,264.5L418.34,260.45L424.76,259.56L426.76,261.8L433.33,259.65L433.33,259.65L433.16,261.83L436.36,265.26L439.26,264.96L441.84,267.28L441.92,268.88L436.27,269.62L438.92,272.34L437.65,274.79L441.01,280.34L439.47,283.71L437.72,283.93L437.61,287.44L440.17,288.48L440.01,290.01L435.75,291.85L435.75,291.85L432.49,291.15L429.68,287.48L425.07,289.58L420.26,287.51L413.57,308.03L413.57,308.03L411.32,307.81L411.75,304.62L409.4,303.09L410.69,301.91L409.42,300.26L406.86,302.69L404.5,300.94L402.11,302.57L398.93,300.47L397.29,305.4L397.29,305.4L396.59,307.53L392.62,309.22L389.81,306.94L380.92,308.27L380.06,306.45L376.64,305.75L377,302.07L377,302.07L381.38,298.98L381.18,290.92L379.25,289.08L375.66,289.29L374.51,286.74L370.48,286.3L365.78,273.6L365.78,273.6L370.29,273.4L372.76,276.48L383.46,271.86L383,268.3L385.34,266.45L382.75,263.9L382.79,259.31L380.94,259.31L383.57,257.59L383.29,251.57L386.08,250.86L386.08,250.86L388.57,251.53L389.02,249.26L389.02,249.26z"/>
            </a>
            <a xlink:href="/departments/72" title="Sarthe">
                <path id="loc-72" class="land" d="M212.79,173.48L217.53,173.69L221.13,168.72L227.62,167.27L231,170.76L231.4,177.19L235.45,177.92L237.35,181.74L241.64,182.71L242.26,180.8L245.76,185.5L248.24,185.13L248.24,185.13L253.14,188.57L250.45,188.91L250.04,190.74L250.04,190.74L248.08,194.2L250.09,195.35L250.37,200.89L248.81,204.08L246.71,203.32L247.32,206.96L244.27,211.55L239.28,214.84L240.72,215.95L240.72,215.95L233.8,220.63L231.06,219.13L230.86,223.74L224.62,221.25L224.62,221.25L222.81,220.81L221.13,222.95L211.26,217.95L209.41,219.76L206.09,216.75L207.87,214.64L200.05,214.77L199.26,211.96L199.26,211.96L199.09,209.34L196.64,207.55L199.71,205.91L198,202.36L203.64,199.77L200.95,194.54L205.52,193.51L204.6,187.38L209.09,184.45L208.57,176.04z"/>
            </a>
            <a xlink:href="/departments/73" title="Savoie">
                <path id="loc-73" class="land" d="M457.07,322.2L458.54,322.81L458.93,328.39L460.61,330.19L462.66,329.67L463.96,333.55L471.09,333.07L473.67,337.43L477.87,336.78L485.37,323.98L487.95,325.65L486.41,326.77L487.47,329.29L492.89,332.2L492.84,334.75L497.44,331.66L497.44,331.66L498.58,336.23L505.74,339.99L505.77,348L513.5,353.8L510.3,358.4L511.33,362.6L509.12,364.91L505.78,364.71L503.46,367.67L501.28,367.76L501.27,369.62L496.06,368.28L490.39,371.19L490.39,371.19L485.39,371.17L482.93,374.53L479.35,373.47L478.04,370.36L475.02,370.17L475.02,370.17L474.57,368.72L470.16,368.53L469.61,360.8L472.29,356.85L469.64,352.06L466.24,351.92L462.79,348.67L460.64,349.57L460.4,354.69L453.37,351.73L448.63,341.49L448.63,341.49L451.21,339.82L451.82,335.73L454.87,334.91z"/>
            </a>
            <a xlink:href="/departments/74" title="Haute-Savoie">
                <path id="loc-74" class="land" d="M497.13,296.6L496.8,298.57L500.12,301.8L497.32,310.33L501.52,311.1L500.47,315.45L503.11,314.76L507.57,323.05L503.27,327.66L498.3,328.23L497.44,331.66L497.44,331.66L492.84,334.75L492.89,332.2L487.47,329.29L486.41,326.77L487.95,325.65L485.37,323.98L477.87,336.78L473.67,337.43L471.09,333.07L463.96,333.55L462.66,329.67L460.61,330.19L458.93,328.39L458.54,322.81L457.07,322.2L457.07,322.2L456.73,312.36L459.5,313.45L459.76,311.46L462.4,310.65L462.4,310.65L469.54,310.23L476.47,305.13L477.06,303.28L475.04,303.56L473.37,299.94L474.7,297.05L485.77,291.23L498.28,293.03z"/>
            </a>
            <a xlink:href="/departments/75" title="Ville de Paris">
                <path id="loc-75" class="land" d="M311.53,141.03L314.45,141.07L315.23,143.69L315.23,143.69L317.16,145.98L311.9,146.11L311.9,146.11L307.49,143.87z"/>
            </a>
            <a xlink:href="/departments/76" title="Seine-Maritime">
                <path id="loc-76" class="land" d="M272.33,66.81l2.96,-0.37l0.15,1.97l9.36,7.86l4.45,10.26l0,0l-1.49,-0.38l-2.5,4.39h2.59l-2.25,3.24l0.99,3.55l-1.29,0.57l2.43,2.67l-1.32,2.12l3.06,0.08l-3.17,6.14l0,0l-2.43,0.53l-3.32,-2.55l-7.82,-1.52l-3.19,2.16l-1.52,5.27l-4.76,0.28l-0.98,2.04l-3.63,0.7l0.62,2.54l-2.34,0.48l-2.13,-1.29l-0.66,-3.26l-1.55,1.56l-2.4,-1.66l0.64,-1.45l2.46,0.53l-0.07,-2.96l-1.61,0.82l-3.21,-3.51l-5.8,1.62l-0.83,-2.67l-2.32,0.81l-3.57,-3.42l-8.41,3.33l0,0l0,0l0,0l-0.52,0.02l0,0l-7.11,-2.38l-1.98,-2.54l5.29,-12.56l16.31,-9.64l21.56,-5.17L272.33,66.81z"/>
            </a>
            <a xlink:href="/departments/77" title="Seine-et-Marne">
                <path id="loc-77" class="land" d="M342.66,127.33L346.49,128.46L346.6,133.92L350.63,138.55L352.3,137.93L353.3,140.49L354.9,139.21L355.54,142.76L358.78,144.92L359.78,144L359.78,144L359.86,146.4L356.38,146.73L358.04,147.88L356.01,149.88L359.07,151.15L358.36,157.59L361.81,156.89L362.86,158.75L362.86,158.75L358.92,161.63L359.59,163.43L356.5,164.29L357.8,166.63L355.54,167.3L356.85,172.91L356.85,172.91L341.62,174.83L340.29,178.21L341.62,181.65L337.05,187.03L337.05,187.03L332.17,189.09L331.32,186.68L328.72,186.8L329.46,188.13L327.44,189.49L316.57,189.46L319.86,184.74L319.15,182.38L315.74,181.04L314.93,177.56L314.93,177.56L317.84,172.93L320.85,172.21L319.05,170.46L318.85,163.21L321.89,154.08L321.89,154.08L323.63,149.63L322.85,146.9L322.85,146.9L321.35,141.83L323.18,138.83L321.01,133.96L321.01,133.96L322.65,129.65L322.65,129.65L324.42,127.85L327.06,130.63L329.37,130.83L331.7,128.58L333.51,130.25L338.37,128.91L339.15,130.2L342.43,129.31z"/>
            </a>
            <a xlink:href="/departments/78" title="Yvelines">
                <path id="loc-78" class="land" d="M277.46,138.46L278.2,136.05L276.18,136.22L275.26,131.32L276.93,131.5L277.81,129.3L281.9,129.82L281.9,129.82L286.66,131.89L291.11,129.89L293,133.62L294.32,131.69L299.11,134.84L303.15,133.61L306.65,137.78L306.49,140.49L306.49,140.49L304.1,144.61L306.17,149.45L306.17,149.45L302.39,151.22L302.14,153.79L298.6,156.13L300.72,158.86L298.87,162.53L295.47,162.34L297.25,164.49L294.91,168.73L294.91,168.73L292.74,169.83L289.91,168.19L289.31,162.76L281.73,155.97L281.49,144.82z"/>
            </a>
            <a xlink:href="/departments/79" title="Deux-Sèvres">
                <path id="loc-79" class="land" d="M178.1,259.89L182.44,258.09L190.25,258.6L192.55,256.44L192.02,254.52L194.58,253.34L196.03,253.46L195.13,255.17L198.64,252.88L207.46,251.84L209.21,252.23L207.84,254.35L210.86,254.52L210.86,254.52L211.52,259.27L213.72,259.49L213.64,266.02L216.51,267.27L213.12,269.29L215.44,269.66L214.19,272.47L216.69,274.62L212.25,280.9L215.09,280.02L214.55,281.72L216.84,283.08L214.79,284.47L213.21,289.64L214.71,290.57L214.15,294.64L216.51,296.26L215.76,299.04L218.33,300.3L220.29,297.7L222.47,298.83L219.62,307.42L224.2,309.09L223.12,312.87L223.12,312.87L219.2,312.27L215.89,315.45L213.6,315.34L213.54,319L210.85,320.37L210.85,320.37L208.52,316.35L204.39,315.48L203.06,313.38L200.01,314.09L197.66,311.79L194.13,312.26L192.17,309.84L189.4,310.39L190,309.12L186.41,307.77L186.51,305.54L183.87,303.88L184,300.36L184,300.36L188.24,299.6L192.73,295.51L189.96,293.77L188.48,294.74L189.84,291.29L188.22,287.36L190.07,285.67L189.6,281.27L187.76,280.16L188.72,278.76L186.95,277.38L187.92,276.54L184.97,272.45L186.16,269.94L180.65,265.42L181.6,263.22z"/>
            </a>
            <a xlink:href="/departments/80" title="Somme">
                <path id="loc-80" class="land" d="M282.69,47.73l0.59,0.47l0,0l0.97,1.44l5.8,-2l4.3,3.19l1.61,-1.27l-0.04,2.8l5.41,2.31l0.37,3.33l2.67,0.67l5.01,-2.49l2.37,1.02l0.22,-1.68l1.09,2.1l3.85,-1.55l1.81,2.32l-4.44,2.64l-0.49,2.84l1.77,1.43l1.67,-2.83l2.3,-0.63l0.77,1.75l0.99,-1.87l0.77,1.92l5.31,2.07l0.22,-3.07l2.86,0.85l-0.99,4.94l3.93,-2.55l0.65,3.73l3.42,-2.11l5.96,-0.04l0,0l3.43,2.73l0,0l-0.75,1.3l2.03,0.47l-6.17,9.76l2.7,8.08l0,0l-1.54,0.98l-1.28,-1.48l-1.02,2.18l-2.07,-1.66l-0.97,2.57l-2.94,-3.1l0.37,2.65l-3.95,0.76l-0.44,3.11l-4.03,-0.86l-1.61,3.44l-1.03,-2.47l-2.27,0.88l-2.71,-2.71L318,95.27l-6.73,-4.43l-5.55,-0.92l-4.85,1.38l-3.92,-2.36l-5.1,1.16l-0.35,-2.45l-2.27,-1.14l0,0l-4.45,-10.26l-9.36,-7.86l-0.15,-1.97l-2.96,0.37l0,0l6.01,-9.22l6.88,1.21l-6.2,-5.56l0.68,-5.65L282.69,47.73z"/>
            </a>
            <a xlink:href="/departments/81" title="Tarn">
                <path id="loc-81" class="land" d="M297.73,426.99L304.03,424.01L307.96,426.27L305.81,427.66L311.94,428.46L320.22,436.88L318.93,438.72L321.08,439.87L321.43,444.5L325.47,449.53L328.98,451.13L332.35,449.26L336.06,450.47L336.98,453.11L336.98,453.11L335.85,455.32L329.48,457.69L324.94,455.07L323.69,460.42L326.13,463.31L325.87,465.93L321.89,468.77L321.89,468.77L314.59,468.99L308.85,466.51L307.04,470.84L305.21,468.98L301.15,470.17L299.38,467.91L299.38,467.91L299.81,463.55L297.74,465.5L294.64,464.16L291.49,459.79L285.27,457.02L286.59,453.46L284.2,453.17L285.96,451.86L283.63,449.94L283.38,447.06L281.08,446.11L279.67,440.24L279.67,440.24L280.61,439.16L278.85,437.88L282.43,437.86L285.87,433L284.07,428.88L288.95,430.08L290.06,428.35L291.33,429.5L291.53,427.27L294.42,428.06L294.35,426.24z"/>
            </a>
            <a xlink:href="/departments/82" title="Tarn-et-Garonne">
                <path id="loc-82" class="land" d="M259.36,413.77L262.33,412.94L260.06,415.28L265.55,420.16L269.25,418.61L268.42,422.03L271.62,423.89L276.24,418.49L279.1,422.45L280.99,421.36L280.41,418.2L283.31,420.05L283.03,418.36L284.03,419.35L285.69,417.57L293.29,416.06L293.29,416.06L292.8,418.77L296.96,419.89L293.66,423.68L297.73,426.99L297.73,426.99L294.35,426.24L294.42,428.06L291.53,427.27L291.33,429.5L290.06,428.35L288.95,430.08L284.07,428.88L285.87,433L282.43,437.86L278.85,437.88L280.61,439.16L279.67,440.24L279.67,440.24L277.05,442.09L276.44,440.86L275.29,443.04L271.79,441.95L271.53,444.13L268.88,444.47L271.52,446.1L265.51,448.94L262.87,445.8L254.71,447.79L254.71,447.79L252.27,447.57L252.64,441.44L246.64,439.27L250.61,432.81L246.91,433.82L245.95,431.77L245.95,431.77L247.96,427.29L252.31,427.62L250.62,424.96L252.83,424.58L254.66,419.76L251.29,417.71L252.41,413.6L254.28,415.68z"/>
            </a>
            <a xlink:href="/departments/83" title="Var">
                <path id="loc-83" class="land" d="M454.26,450.92l2.89,-0.87l0.84,1.69l3.16,-2.26l3.99,5.06l8.01,-7.44l6.98,3.75l1.32,-3.3l9.14,0.17l0,0l0.98,2.33l3.95,0.41l1.89,6.46l4.67,1.67l-1.4,3.96l2.1,3l0,0l-3.08,3.67l-4.64,-0.23l-1.09,3.88l-5.45,3.87l4.65,0.76l-2.42,5.35l-3.22,-0.95l-7.33,2.54l-0.67,3.14l-6.81,-1.55l-1.84,1.58l-0.2,3.49l-2.49,-0.12l1.21,-2.85l-4.28,0.06l-4.09,-2.63l-1.66,1.26l2.76,1.95l-4.96,1.03l-0.84,-3.7l-4.7,-1.61l-1.03,-2.04l0,0l0.48,-3.15l3.33,-2.66l-3.8,-2.07l1.38,-2.12l-0.93,-2.46l4.41,-1.24l-3.72,-3.54l1.06,-3.89l-2.86,-1.31l1.8,-4l4.04,-0.93l0.71,-1.7l-2.45,-1.95l0,0L454.26,450.92z"/>
            </a>
            <a xlink:href="/departments/84" title="Vaucluse">
                <path id="loc-84" class="land" d="M408.19,416.54l5.73,0.97l1.26,4.96l10,-4.63l2.02,1.74l2.46,-2.16l-0.51,4.9l8.29,0.92l1.16,3.41l4.79,2.27l0,0l0.25,3.02l1.65,-0.21l-1.49,6.68l4.16,2.06l-2.62,5.66l4.53,-0.52l4.38,5.31l0,0l-0.22,0.52l0,0l-6.26,3.7l-3.15,-0.03l-9.08,-4.49l-5.5,0.14l-5.26,-2.9l-3.95,-5.21l-8.92,-2.64l0,0l4.28,-4.86l-5.12,-4.28l0.04,-6.1l-2.99,-4.8l0,0L408.19,416.54zM422.31,411.26l0.49,0.5l0,0l2.86,2.06l-3.49,4.85l-4.05,-0.68l-0.65,-2.61l2.88,-3.71l0,0l0,0l0,0l1.88,-0.54l0,0L422.31,411.26z"/>
            </a>
            <a xlink:href="/departments/85" title="Vendée">
                <path id="loc-85" class="land" d="M121.69,256.73l0.86,3.24l3.05,1.5l0.3,3.31l-2.43,-3.85l-3.83,-2.02l-0.28,-2.03L121.69,256.73zM132.98,256.65l3.28,4.13l6.41,2l0.98,2.69l7.23,1.43l1.93,-1.22l-2.29,-8.09l2.2,-1.52l1.3,0.47l0.37,6.31l4.07,-1.85l-0.56,-4.51l3.23,-0.14l1.24,-3.08l5.11,3.43l0,0l3.84,1.48l3.26,-0.86l3.5,2.58l0,0l3.5,3.33l-0.95,2.2l5.51,4.52l-1.19,2.51l2.94,4.09l-0.97,0.84l1.77,1.38l-0.95,1.4l1.83,1.11l0.47,4.4l-1.86,1.68l1.63,3.94l-1.36,3.45l1.48,-0.97l2.76,1.74l-4.49,4.09l-4.23,0.75l0,0l-2.15,-2.28l-5.48,1.91l-1.17,-0.68l1.31,-2.94l-7.73,3.27l0,0l-3.56,0.1l-0.17,2.7l-4.55,-3.84l0.84,2.57l-1.99,-3.06l-5.16,-0.01l-1.94,-3.69l-5.58,-0.97l-6.83,-4.49L138,282l-11.84,-12.79l-0.41,-4.08L132.98,256.65z"/>
            </a>
            <a xlink:href="/departments/86" title="Vienne">
                <path id="loc-86" class="land" d="M210.86,254.52L211.52,252.3L213.6,253.17L214.81,248.68L217.39,248.44L217.39,248.44L218.48,251.17L222.7,251.41L222.56,254.83L227.73,255.31L228.58,262.64L239.98,261.03L238.71,258.06L243.85,260.02L244.38,264.3L248.19,267.54L251.15,273.68L251.15,273.68L253.61,276.73L252.92,282.54L262.71,288.28L262.89,291.71L265.43,292.6L264.03,295.53L264.03,295.53L262.03,297.76L258.65,296.86L256.82,301.84L252.59,301.3L250.38,304.79L248.74,304.75L249.34,310.91L249.34,310.91L244.71,310.23L243.64,312.84L239.84,314.26L235.53,310.41L233.52,312.5L235.07,314.65L232.82,315.68L223.12,312.87L223.12,312.87L224.2,309.09L219.62,307.42L222.47,298.83L220.29,297.7L218.33,300.3L215.76,299.04L216.51,296.26L214.15,294.64L214.71,290.57L213.21,289.64L214.79,284.47L216.84,283.08L214.55,281.72L215.09,280.02L212.25,280.9L216.69,274.62L214.19,272.47L215.44,269.66L213.12,269.29L216.51,267.27L213.64,266.02L213.72,259.49L211.52,259.27z"/>
            </a>
            <a xlink:href="/departments/87" title="Haute-Vienne">
                <path id="loc-87" class="land" d="M264.03,295.53L269.24,296.34L271.33,294.64L273.88,297.86L273.88,297.86L274.8,302.32L272.15,305.7L277.24,310.07L276.75,312.28L279.05,313.81L278.73,317.98L280.56,319.82L277.81,322.57L283.19,322.96L283.36,325L281.21,325.61L284.07,328.37L288.66,326.47L288.72,328.51L293.26,330.68L293.91,336.46L293.91,336.46L292.92,338.44L289.28,337.48L280.84,344.88L276.48,344.34L271.17,350.12L268.51,348.76L267.16,351.5L267.16,351.5L261.66,349.63L263.55,346.68L260.19,346.37L257.39,341.66L252.37,342.23L251.19,340.95L248.84,343.78L246.32,341.25L247.29,338.31L241.28,335.78L241.28,335.78L244.79,332.69L244.66,330.2L247.6,330.95L248.82,322.6L250.81,323.49L254.18,321.11L253.57,318L248.91,315.51L249.34,310.91L249.34,310.91L248.74,304.75L250.38,304.79L252.59,301.3L256.82,301.84L258.65,296.86L262.03,297.76z"/>
            </a>
            <a xlink:href="/departments/88" title="Vosges">
                <path id="loc-88" class="land" d="M510.62,165.21L508.87,175.28L513.19,176.07L513.99,177.9L513.99,177.9L508.19,188.16L508.91,190.38L503.38,197.19L501.41,203.92L502.37,206.45L498.91,208.16L498.91,208.16L498.39,208.6L498.39,208.6L489.27,200.5L484.08,204.23L480.39,199.59L472.71,201.36L469.69,195.68L465.89,196.94L462.14,201.12L461.45,198.55L459.44,201.73L459.44,201.73L457.87,198.66L455.4,200.09L454.95,195.09L448.77,191.88L450.54,190.46L450.95,186.05L453.06,185.1L444.47,175.55L440.41,176.58L441.04,173.59L439.03,172.85L442.17,171.01L442.17,171.01L448.49,170.7L449.35,167.81L452.52,168.6L454.46,166.27L454.46,166.27L460.29,166.4L458.25,171.69L461.38,170.2L460.26,172.28L462.15,172.54L462.64,175.42L467.19,174.8L467.86,173.31L469.06,175.19L470.48,171.74L473.91,172.4L476.49,170.14L480.37,172.84L486.38,170.33L488.27,171.3L487.77,169.75L490.01,167.76L490.99,170.55L496.69,173.04z"/>
            </a>
            <a xlink:href="/departments/89" title="Yonne">
                <path id="loc-89" class="land" d="M337.05,187.03L341.62,181.65L340.29,178.21L341.62,174.83L356.85,172.91L356.85,172.91L357.43,174.88L360.57,174.39L365.36,180.48L363.7,185.71L365.84,185.38L367.37,188.48L370.74,186.88L370.38,188.95L372.52,189.86L375.87,196.23L375.03,198.21L376.81,197.06L377.59,198.41L376.82,201.53L383.04,201.78L383.68,200.51L385.76,201.73L388.13,199.65L389.59,200.77L390.59,198.88L391.35,201.47L393.35,201.79L393.35,201.79L394.13,203.02L391.41,204.94L392.42,207.09L394.9,206.59L395.03,212.15L391.09,214.16L392.98,216.74L390.63,216.95L391.55,218.1L385.95,226.99L386.28,231.08L383.9,233.58L385.62,237.73L385.62,237.73L381.3,239.52L379.08,234.38L375.91,236.13L375.66,231.91L373.62,235.33L372.36,233.73L369.83,234.14L367.68,230.97L364.05,230.61L360.39,224.19L359.94,228.3L355.96,227.57L354.07,229.82L346.49,226.78L343.9,222.55L340.55,224.45L338.69,223.68L338.69,223.68L336.6,216.77L333.35,214.61L333.51,212.12L340.72,210.3L340.05,202.94L344.98,198.82L344.84,195.29L341.73,192.6L340.89,188.88z"/>
            </a>
            <a xlink:href="/departments/90" title="Territoire de Belfort">
                <path id="loc-90" class="land" d="M498.91,208.16L500.16,210.59L507.24,214.26L505.95,221.67L509.3,222.24L511.23,227.75L511.23,227.75L505.02,228.3L505.8,230.78L503.24,232.05L503.24,232.05L501.85,229.58L503.4,227.11L501.62,224.88L497.69,224.1L497.69,224.1L495.68,212.74L498.39,208.6L498.39,208.6z"/>
            </a>
            <a xlink:href="/departments/91" title="Essonne">
                <path id="loc-91" class="land" d="M306.17,149.45L310.83,150.95L310.83,150.95L315.35,152.47L319.52,151.12L321.89,154.08L321.89,154.08L318.85,163.21L319.05,170.46L320.85,172.21L317.84,172.93L314.93,177.56L314.93,177.56L311.81,176.5L308.32,178.63L306.57,175.79L304.93,178.63L297.89,179.38L297.89,179.38L296.39,177.92L297.21,172.25L295.15,171.97L294.91,168.73L294.91,168.73L297.25,164.49L295.47,162.34L298.87,162.53L300.72,158.86L298.6,156.13L302.14,153.79L302.39,151.22z"/>
            </a>
            <a xlink:href="/departments/92" title="Hauts-de-Seine">
                <path id="loc-92" class="land" d="M309.85,137.88L312.08,138.48L311.53,141.03L311.53,141.03L307.49,143.87L311.9,146.11L311.9,146.11L310.83,150.95L310.83,150.95L306.17,149.45L306.17,149.45L304.1,144.61L306.49,140.49L306.49,140.49z"/>
            </a>
            <a xlink:href="/departments/93" title="Seine-Saint-Denis">
                <path id="loc-93" class="land" d="M321.01,133.96L323.18,138.83L321.35,141.83L322.85,146.9L322.85,146.9L320.27,143.91L315.23,143.69L315.23,143.69L314.45,141.07L311.53,141.03L311.53,141.03L312.08,138.48L309.85,137.88L309.85,137.88L313.35,136.4L316.39,137.61z"/>
            </a>
            <a xlink:href="/departments/94" title="Val-de-Marne">
                <path id="loc-94" class="land" d="M315.23,143.69L320.27,143.91L322.85,146.9L322.85,146.9L323.63,149.63L321.89,154.08L321.89,154.08L319.52,151.12L315.35,152.47L310.83,150.95L310.83,150.95L311.9,146.11L311.9,146.11L317.16,145.98z"/>
            </a>
            <a xlink:href="/departments/95" title="Val-d'Oise">
                <path id="loc-95" class="land" d="M285.85,120.07L287.35,120.55L287.42,123.28L291.31,124.35L298.23,123.61L301.51,121.43L305.23,124.39L307.22,123.39L307.58,125.14L311.17,122.94L318.5,127.99L321.15,126.86L322.65,129.65L322.65,129.65L321.01,133.96L321.01,133.96L316.39,137.61L313.35,136.4L309.85,137.88L309.85,137.88L306.49,140.49L306.49,140.49L306.65,137.78L303.15,133.61L299.11,134.84L294.32,131.69L293,133.62L291.11,129.89L286.66,131.89L281.9,129.82L281.9,129.82z"/>
            </a>
        </g>
    </svg>
`;