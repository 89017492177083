import PropTypes from 'prop-types';
import React from "react";
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { navigate } from 'gatsby';

import AppConstants from '../../app/constants';
import Map from "./DepartmentsMapWithLinks";
import RegionsMap from './RegionsMapWithLinks';

import './Map.scss';

const types = {
  departments: {
    map: Map,
    mapSelector: '#departments-map',
    selectorPrefix: '',
    basePath: AppConstants.ROUTE_PATHS.departments,
  },
  regions: {
    map: RegionsMap,
    mapSelector: '#regions-map',
    selectorPrefix: '#reg',
    basePath: AppConstants.ROUTE_PATHS.regions,
  },
}

const France = ({color, highlightColor, departements, type}) => {

  return (
    <SvgLoader svgXML={types[type].map} className="svg-map">
      <SvgProxy
        selector={types[type].mapSelector}
        fill={color}
      />
      {departements.map((dpt, departmentIndex) => (
        <SvgProxy
          key={departmentIndex}
          selector={`#loc-${dpt.code}`}
          fill={dpt.color}
          onClick={(e) => {
            e.preventDefault();
            navigate(`${types[type].basePath}/${e.target.id.replace('loc-', '')}`);
          }}
        />
      ))}
    </SvgLoader>
  );
};

France.propTypes = {
  color: PropTypes.string,
  departements: PropTypes.array,
  highlightColor: PropTypes.string,
  type: PropTypes.oneOf(['regions', 'departments']),
};

France.defaultProps = {
  color: AppConstants.MAP_COLORS['0'],
  highlightColor: "rgba(255,54,0,0.72)",
  departements: [],
  type: 'departments'
};

export default France;
