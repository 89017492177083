import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import AppConstants from '../app/constants';
import GlobalActivity from '../components/GlobalActivity/GlobalActivity';
import MapDataCard from '../components/MapDataCard/MapDataCard';
import PageTitle from '../components/layout/PageTitle/PageTitle';
import SEO from '../components/seo';

import departmentsReaCapacity from '../data/dep_rea_capa.json';
import regionsReaCapacity from '../data/reg_rea_capa.json';

const IndexPage = ({ franceStats }) => {

  const {
    departments, mapDepartments, mapRegions, regions,
  } = franceStats;

  const query = useStaticQuery(graphql`
    query GlobalActivityQuery {
      countriesStats(countryInfo: {iso2: {eq: "FR"}}) {
        active
        cases
        casesPerOneMillion
        country
        critical
        deaths
        deathsPerOneMillion
        recovered
        todayCases
        todayDeaths
      }
    }
  `);

  const regionsData = Object.values(regions).map((region) => ({
    ...region,
    rea_capacity: regionsReaCapacity[region.code].rea_capacity,
    percentRea: `${((region.totalRea / regionsReaCapacity[region.code].rea_capacity) * 100).toFixed(0)}%`
  }));

  const departmentsData = Object.values(departments).map((department) => ({
    ...department,
    rea_capacity: departmentsReaCapacity[department.code].rea_capacity,
    percentRea: `${((department.totalRea / departmentsReaCapacity[department.code].rea_capacity) * 100).toFixed(0)}%`
  }));

  return (
    <>
      <SEO title="Evolution du Coronavirus Covid19 en France et dans le monde" />

      <PageTitle title="France" />

      <GlobalActivity
        data={franceStats}
        globalStats={query.countriesStats}
        cumulChartItem={['total', 'dc', 'hosp', 'rea', 'rad']}
        dailyChartItems={['dc', 'hosp', 'rea', 'rad']}
      />

      <MapDataCard
        title="Les régions les plus touchés"
        mapType="regions"
        mapDepartments={mapRegions}
        tableHeaders={['', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis', 'Capa Réa', '']}
        tableDataKeys={['nom', 'total', 'totalDc', 'totalHosp', 'totalRea', 'totalRad', 'rea_capacity', 'percentRea']}
        tableData={regionsData.sort((a, b) => b.total - a.total).slice(0, 10)}
        seeAllPath={AppConstants.ROUTE_PATHS.departments}
        pathBase={AppConstants.ROUTE_PATHS.regions}
      />

      <MapDataCard
        title="Les départements les plus touchés"
        mapType="departments"
        mapDepartments={mapDepartments}
        tableHeaders={['', 'Total', 'Décès', 'Hosp', 'Réa', 'Rétablis', 'Capacité Réa', '']}
        tableDataKeys={['nom', 'total', 'totalDc', 'totalHosp', 'totalRea', 'totalRad', 'rea_capacity', 'percentRea']}
        tableData={departmentsData.sort((a, b) => b.total - a.total).slice(0, 10)}
        pathBase={AppConstants.ROUTE_PATHS.departments}
      />
    </>
  );
};



export default IndexPage;
