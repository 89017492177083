import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'gatsby';

import CardTable from '../Table/CardTable';
import FranceMap from '../FranceMap';

const MapDataCard = ({
  title, mapDepartments, mapType, pathBase, tableData, tableDataKeys, tableHeaders,
}) => (
    <Card>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={12} md={5}>
            <Card>
              <Card.Body>
                <FranceMap
                  type={mapType}
                  departements={mapDepartments}
                />
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={7}>
            <CardTable
              headers={tableHeaders}
              dataKeys={tableDataKeys}
              data={tableData}
              pathBase={pathBase}
            />
          </Col>
          
        </Row>
      </Card.Body>

      <Card.Footer>
        <Link to={pathBase}>Voir tout</Link>
      </Card.Footer>
    </Card>
);

MapDataCard.propTypes = {
  mapDepartments: PropTypes.array,
  mapType: PropTypes.string,
  pathBase: PropTypes.string,
  tableDataKeys: PropTypes.array.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  title: PropTypes.string,
};

MapDataCard.defaultProps = {
  mapDepartments: [],
  pathBase: null,
  title: '',
  mapType: null,
};

export default MapDataCard;
